import React, { useEffect, useState, useRef, lazy, Suspense } from 'react';
import axios from 'axios';
import L from 'leaflet';
// import { MapContainer, TileLayer, Marker, Popup, Polygon } from 'react-leaflet';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import 'leaflet/dist/leaflet.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Slider from 'rc-slider';
import { executeLaravelFrontAPI, s3url, formatNumber,createGa4Event, isMobileDevice, getMinMaxCarpets,  getMinMaxPrice,  appAbsPath } from './../../Admin/Utils';
// import WishListBtn from './WishListBtn';
// import { CompareProjectContext } from '../Layouts/CompareProjectContext';
import defaultGallery from '../../../images/default-corporate-image.webp';
import direction from '../../../images/directions.svg';
import { Icon } from '@iconify/react';
import NewFilterSidebar from './NewFilterSidebar';
import { debounce } from 'lodash';
import Placeholder from 'react-bootstrap/Placeholder';
import InfiniteScroll from 'react-infinite-scroll-component';
// import { param } from 'jquery';
import ProjectCard from '../Layouts/ProjectCard';

import polygonCoordsSus from './LocalityCoordinate/polygonCoordsSus';
import polygonCoordsMoshi from './LocalityCoordinate/polygonCoordsMoshi';
import polygonCoordsNIBM from './LocalityCoordinate/polygonCoordsNIBM';
import polygonCoordsBavdhan from './LocalityCoordinate/polygonCoordsBavdhan';
import polygonCoordsTathawade from './LocalityCoordinate/polygonCoordsTathawade';
import polygonCoordsPunawale from './LocalityCoordinate/polygonCoordsPunawale';
import polygonCoordsKothrud from './LocalityCoordinate/polygonCoordsKothrud';
import polygonCoordsKharadi from './LocalityCoordinate/polygonCoordsKharadi';
import polygonCoordsHadapsar from './LocalityCoordinate/polygonCoordsHadapsar';
import polygonCoordsRavet from './LocalityCoordinate/polygonCoordsRavet';
import polygonCoordsWagholi from './LocalityCoordinate/polygonCoordsWagholi';
import polygonCoordsBaner from './LocalityCoordinate/polygonCoordsBaner';
import polygonCoords from './LocalityCoordinate/polygonCoords';

const Loader = lazy(() => import('../../Loader'));

const isMobile = isMobileDevice();
// const itemsPerPageOptions = [5, 50, 100, 500, 1000];
let flagPage = false;
let newPage;

const NewMapView = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const mapRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [noDataFound, setNoDataFound] = useState(false);
  const currentUrl = location.pathname;

  const [currentPage, setCurrentPage] = useState(0);
  // const [paginatedData, setPaginatedData] = useState([]);
  const [projects, setResponseData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [hoveredProject, setHoveredProject] = useState(null);

  const [sortCriteria, setSortCriteria] = useState('relevance');

  const [priceRange, setPriceRange] = useState([500000, 50000000]);
  const [builtUpArea, setBuiltRange] = useState([100, 5000]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedPossessionStatus, setSelectedPossessionStatus] = useState([]);
  const [selectedBHK, setSelectedBHK] = useState([]);
  const [selectedConf, setSelectedConf] = useState([]);

  const [getProjectstatusData, getProjectStatus] = useState([]);
  const [getProjectBhkTypeData, getProjectBhkType] = useState([]);
  const [numericParts, getNumericParts] = useState([]);
  const [nonNumericParts, getNonNumericParts] = useState([]);

  const initialDisplayLimit = 20; // Change this number to show more/less items initially
  const [showAllBhk, setShowAllBhk] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [getAllParams1, setAllParams1] = useState([]);
  const urlParams = new URLSearchParams(location.search);
  let locality_name = urlParams.get('locality_name');
  const [visible, setVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [popupVisible, setPopupVisible] = useState(false);
  const [currentPopupProject, setCurrentPopupProject] = useState(null);

  const [isLayerOff, setLayerOff] = useState(isMobile ? false : true);
  const [isMapBtn, setMapviewBtn] = useState(true);
  const [isListBtn, setListviewBtn] = useState(false);

  const [isIrfsMapPage, setIrfsMapPage] = useState(false);


  const getAllParams = (location) => {
    const searchParams = new URLSearchParams(location.search);
    const is_new_in_town = searchParams.get('is_new_in_town');
    const is_hot_selling = searchParams.get('is_hot_selling');
    const is_affordable = searchParams.get('is_affordable');
    const is_ready_to_move = searchParams.get('is_ready_to_move');
    const is_great_view = searchParams.get('is_great_view');
    const is_mandate = searchParams.get('is_mandate');
    const allParams = {};

    for (const [key, value] of searchParams.entries()) {
      allParams[key] = value;
    }
    // allParams.city = city;
    if (is_new_in_town === "true") {
      allParams.is_new_in_town = true;
    }
    if (is_hot_selling === "true") {
      allParams.is_hot_selling = true;
    }
    if (is_hot_selling === "true") {
      allParams.is_hot_selling = true;
    }
    if (is_affordable === "true") {
      allParams.is_affordable = true;
    }
    if (is_ready_to_move === "true") {
      allParams.is_ready_to_move = true;
    }
    if (is_great_view === "true") {
      allParams.is_great_view = true;
    }
    if (is_mandate === "true") {
      allParams.is_mandate = true;
    }
    if(window.location.pathname.includes('irfs')){
      allParams.is_irfs = true;
    }
    allParams.page = currentPage + 1;
    allParams.per_page = 5;
    return allParams;
  }
  const [params, setParams] = useState(getAllParams(location));
  const boundsCoordinates = [
    [85, -180],  // Top-left corner of the world
    [85, 180],   // Top-right corner of the world
    [-85, 180],  // Bottom-right corner of the world
    [-85, -180]  // Bottom-left corner of the world
  ];
  let localityValue, latlong;
  if (locality_name) {
    localityValue = locality_name.toLowerCase();
    if (localityValue === 'sus') {
      latlong = [18.53869, 73.74532];
    } else if (localityValue === 'moshi') {
      latlong = [18.68686, 73.85851];
    } else if (localityValue === 'nibm') {
      latlong = [18.45926, 73.90726];
    } else if (localityValue === 'bavdhan') {
      latlong = [18.50754, 73.78353];
    } else if (localityValue === 'kharadi') {
      latlong = [18.5449, 73.9397];
    } else if (localityValue === 'baner') {
      latlong = [18.559, 73.7658];
    } else if (localityValue === 'wakad') {
      latlong = [18.591, 73.756];
    } else if (localityValue === 'hinjewadi') {
      latlong = [18.591, 73.756];
    } else if (localityValue === 'pashan') {
      latlong = [18.537, 73.807];
    } else if (localityValue === 'balewadi') {
      latlong = [18.576, 73.779];
    } else if (localityValue === 'aundh') {
      latlong = [18.563, 73.812];
    } else if (localityValue === 'kondhwa') {
      latlong = [18.478, 73.891];
    } else if (localityValue === 'punawale') {
      latlong = [18.632, 73.738];
    } else if (localityValue === 'ravet') {
      latlong = [18.651, 73.735];
    } else if (localityValue === 'tathawade') {
      latlong = [18.625, 73.765];
    } else if (localityValue === 'wagholi') {
      latlong = [18.591, 73.982];
      // }else if (localityValue.toLowerCase() === 'manjri') {
      //   latlong = [ 18.489, 73.941 ];
      // }else if (localityValue.toLowerCase() === 'undri') {
      //   latlong = [ 18.469, 73.917 ];
      //   latlong = [ 18.652, 73.718 ];
      // }else if(localityValue.toLowerCase() === 'kalyani nagar'){
      //   latlong = [ 18.548, 73.902 ];
      // }else if(localityValue.toLowerCase() === 'koregaon park'){
      //   latlong = [ 18.536, 73.893 ];
      // }else if(localityValue.toLowerCase() === 'viman nagar'){
      //   latlong = [ 18.567, 73.914 ];
    } else if (localityValue === 'kondhwa') {
      latlong = [18.478, 73.891];
    } else if (localityValue === 'kothrud') {
      latlong = [18.507, 73.807];
    } else if (localityValue === 'karve nagar') {
      latlong = [18.494, 73.822];
    } else {
      latlong = [18.49333, 73.85233];
    }
  } else {
    // console.log('Locality is undefined');
    latlong = [18.49333, 73.85233];
  }
  React.useEffect(() => {
    const handleScroll = () => {
      const fixedDiv = document.getElementById('sticky-map-rightsidebarwrap');
      const fixedDivMap = document.getElementById('sticky-map-rightsidebar');
      const footer = document.getElementById('footerdiv');

      if (fixedDiv && footer) {
        const footerRect = footer.getBoundingClientRect();
        // const fixedDivRect = fixedDiv.getBoundingClientRect();

        if (window.scrollY > 2 && footerRect.top > window.innerHeight) {
          fixedDiv.classList.add('stickySidebar');
          fixedDiv.style.position = 'fixed';
          fixedDiv.style.bottom = 'initial';
          fixedDiv.style.height = 'calc(100vh - 160px)';
          fixedDivMap.classList.add('col-lg-5');
          fixedDivMap.classList.remove('col-lg-12');
        } else if (footerRect.top <= window.innerHeight) {
          fixedDiv.classList.remove('stickySidebar');
          fixedDiv.style.position = 'relative';
          fixedDiv.style.bottom = '0';
          fixedDiv.style.height = 'auto';
          fixedDivMap.classList.add('col-lg-12');
          fixedDivMap.style.top = 'unset';
        } else {
          fixedDiv.classList.remove('stickySidebar');
          fixedDiv.style.position = 'fixed';
          fixedDiv.style.bottom = 'initial';
          fixedDiv.style.right = '0px';
          fixedDiv.style.height = 'calc(100vh - 160px)';
          fixedDivMap.classList.add('col-lg-12');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    const fetchCoordinates = async (localityName) => {
    try {
      const defaultLatLong = [18.49333, 73.85233];
      const response = await axios.get('https://nominatim.openstreetmap.org/search', {
        params: {
          q: `${localityName} Maharashtra India`,
          format: 'json',
        },
      });

      const coordinates = response.data.length > 0
        ? [parseFloat(response.data[0].lat), parseFloat(response.data[0].lon)]
        : defaultLatLong;

      const [lat, lon] = coordinates;
      const newLatLong = [lat, lon];
      let zoomLevel = locality_name ? 14 : 10; // Adjust the zoom level as needed
     
      // Check if the map is already initialized
      if (mapRef.current) {
        // Update the map view if the map already exists
        mapRef.current.on('dragend', () => {
          if(locality_name!==undefined && locality_name!==null && locality_name!==''){
            console.log("locality_name: ",locality_name)
            locality_name = '';
            console.log('Dragend event triggered');
            // Only execute if the mouse was dragged (not just clicked)
            const url = new URL(window.location.href);
            const urlParams = new URLSearchParams(url.search);
            urlParams.delete('locality_name');
            // window.history.replaceState({}, '', `${url.pathname}?${urlParams}`);
            location.search = urlParams.toString();         
            zoomLevel = 14
            // // Always reset after move ends
            fetchProjectsInBounds(mapRef.current);
            // return;
          }
          
          // isUserInteracting = true;
          // drag = false;  // Reset drag status
      });
      // mapRef.current.setView(newLatLong, zoomLevel);
     

      } else {
        // Initialize the map if it doesn't exist yet
        const newMapInstance = L.map('sticky-map-rightsidebar', { scrollWheelZoom: false }).setView(newLatLong, zoomLevel);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(newMapInstance);
        setMap(newMapInstance);
        mapRef.current = newMapInstance;
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };

// Debounce function to avoid multiple calls
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};


  let newMap;
  useEffect(() => {
    if (!mapRef.current) {
      const zoomLevel = locality_name ? 14 : 10;
      console.log("locality_name111: ",locality_name)
      newMap = L.map('sticky-map-rightsidebar', { scrollWheelZoom: false }).setView([latlong[0], latlong[1]], zoomLevel);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        // attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(newMap);
      // newMap.on('moveend', () => {
      //   // console.log('Moveend event triggered');
      //   fetchProjectsInBounds(newMap);
      // });

      newMap.on('dragend', debounce(() => {
        fetchProjectsInBounds(newMap);
      }, 300)); // Debounced dragend event

      newMap.on('zoomend', debounce(() => {
        fetchProjectsInBounds(newMap);
      }, 300)); // Debounced dragend event


      // newMap.on('zoomend', () => {
      //   // console.log('Zoomend event triggered');
      //   fetchProjectsInBounds(newMap);
      // });

      setMap(newMap);
      mapRef.current = newMap;
      newMap.on('click', () => {
        setHoveredProject(null);
      });
      if (locality_name) {
        fetchCoordinates(locality_name); // Call fetchCoordinates here to update the map view based on locality name
      }
      const locationPolygonCoords = {
        'wakad': polygonCoords,
        'baner': polygonCoordsBaner,
        'wagholi': polygonCoordsWagholi,
        'ravet': polygonCoordsRavet,
        'hadapsar': polygonCoordsHadapsar,
        'kharadi': polygonCoordsKharadi,
        'kothrud': polygonCoordsKothrud,
        'punawale': polygonCoordsPunawale,
        'tathawade': polygonCoordsTathawade,
        'bavdhan': polygonCoordsBavdhan,
        'nibm': polygonCoordsNIBM,
        'moshi': polygonCoordsMoshi,
        'sus': polygonCoordsSus,
        // Add more locations here
      };
      if (locality_name in locationPolygonCoords) {
        // Get the polygon coordinates for the current location
        const currentPolygonCoords = locationPolygonCoords[locality_name];

        // Create the polygon
        const overlayPolygon = L.polygon([boundsCoordinates, currentPolygonCoords], {
          color: '#192951',
          weight: 1,
          fillColor: '#192951',
          fillOpacity: 0.3,
          opacity: 0.3
        });

        // Add the polygon to the map
        overlayPolygon.addTo(newMap);
      }

      if (locality_name) {
        // Handle different localities
        switch (locality_name) {
          case 'wakad':
            L.polygon(polygonCoords, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'baner':
            L.polygon(polygonCoordsBaner, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'wagholi':
            L.polygon(polygonCoordsWagholi, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'ravet':
            L.polygon(polygonCoordsRavet, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'hadapsar':
            L.polygon(polygonCoordsHadapsar, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'kharadi':
            L.polygon(polygonCoordsKharadi, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'kothrud':
            L.polygon(polygonCoordsKothrud, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'punawale':
            L.polygon(polygonCoordsPunawale, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'tathawade':
            L.polygon(polygonCoordsTathawade, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'bavdhan':
            L.polygon(polygonCoordsBavdhan, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'nibm':
            L.polygon(polygonCoordsNIBM, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'moshi':
            L.polygon(polygonCoordsMoshi, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'sus':
            L.polygon(polygonCoordsSus, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
        }
      }
    }
    // window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setParams(getAllParams(location));
    let params = new URLSearchParams(location.search);
    let configurationValue = params.get('configuration');
    let projectStatusValue = params.get('project_status');
    let possessionStatusValue = params.get('possession_months');
    let minpriceValue = params.get('minprice');
    let maxpriceValue = params.get('maxprice');
    let minBuiltValue = params.get('carpet_min');
    let maxBuiltValue = params.get('carpet_max');
    if (configurationValue) {
      let configurationArray = configurationValue.split(',');
      setSelectedBHK(configurationArray);
    }
    if (projectStatusValue) {
      let projectStatusArray = projectStatusValue.split(',');
      setSelectedStatus(projectStatusArray);
    }
    if (possessionStatusValue) {
      let possessionStatusArray = possessionStatusValue.split(',');
      setSelectedPossessionStatus(possessionStatusArray);
    }
    if (minpriceValue || maxpriceValue) {
      setPriceRange([minpriceValue, maxpriceValue]);
    }

    if (window.location.pathname.includes('irfs')) {
      setIrfsMapPage(true);
      params.is_irfs = true;
    }

    if (minBuiltValue || maxBuiltValue) {
      setBuiltRange([minBuiltValue, maxBuiltValue]);
    }
  }, [location.pathname, location.search]);


  if (selectedStatus.length != 0) {
    params.project_status = selectedStatus;
  }
  if (selectedPossessionStatus.length != 0) {
    params.possession_months = selectedPossessionStatus;
  }
  if (numericParts.length !== 0) {
    params.bhk = numericParts ? numericParts : 0;
    params.type = nonNumericParts;
  }
  if (selectedConf.length != 0) {
    params.configurations = selectedConf.toString().toLowerCase();
    // console.log("Configurations: ", selectedConf);
  }
  if (sortCriteria.length != 0) {
    params.orderby = sortCriteria;
  }
  if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
    let minPrice = Math.floor(priceRange[0]);
    let maxPrice = Math.ceil(priceRange[1]);
    params.minprice = minPrice;
    params.maxprice = maxPrice;
  }
  if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
    let minBuilt = Math.floor(builtUpArea[0]);
    let maxBuilt = Math.ceil(builtUpArea[1]);
    params.carpet_min = minBuilt;
    params.carpet_max = maxBuilt;
  }

  if (isIrfsMapPage == true) {
    params.is_irfs = true;
  }

  useEffect(() => {
    // This effect only clears the paginated data
    setResponseData([]);
    setPage(1);
    window.scrollTo(0, 0);
    if (map) {
      // console.log('Map instance exists, calling fetchProjectsInBounds');

      const searchParams =  urlParams.get('searchtype');
      if ((locality_name == '' || locality_name == undefined ) && searchParams !== 'locality_name') {
        if (mapRef.current) {
          mapRef.current.setView([18.49333, 73.85233], 10);
        }
      }
      fetchProjectsInBounds(map);
    }
  }, [location.search, selectedStatus, selectedPossessionStatus, selectedBHK, sortCriteria, priceRange, map, builtUpArea]); // Dependencies that trigger data reset

  // useEffect(() => {
  //   setTimeout(async () => {
  //     if (projects.length === 0 || map) {
  //       // getProjectStatusFunc();
  //       getProjectBhkTypeFunc();
  //       setAllParams1(params);
  //       getProjects(params, 0);
  //     }
  //   }, 1000);
  // }, [params, selectedStatus, selectedPossessionStatus, selectedBHK, sortCriteria, map]);

  // useEffect(() => {
  //   setParams(getAllParams(location));
  //   let params = new URLSearchParams(location.search);
  //   let configurationValue = params.get('configuration');
  //   let projectStatusValue = params.get('project_status');
  //   let possessionStatusValue = params.get('possession_months');
  //   let minpriceValue = params.get('minprice');
  //   let maxpriceValue = params.get('maxprice');
  //   let minBuiltValue = params.get('carpet_min');
  //   let maxBuiltValue = params.get('carpet_max');
  //   if (configurationValue) {
  //     let configurationArray = configurationValue.split(',');
  //     setSelectedBHK(configurationArray);
  //   }
  //   if (projectStatusValue) {
  //     let projectStatusArray = projectStatusValue.split(',');
  //     setSelectedStatus(projectStatusArray);
  //   }
  //   if (possessionStatusValue) {
  //     let possessionStatusArray = possessionStatusValue.split(',');
  //     setSelectedPossessionStatus(possessionStatusArray);
  //   }
  //   if (minpriceValue || maxpriceValue) {
  //     setPriceRange([minpriceValue, maxpriceValue]);
  //   }
  //   if (minBuiltValue || maxBuiltValue) {
  //     setBuiltRange([minBuiltValue, maxBuiltValue]);
  //   }
  // }, [location.pathname, location.search,]);
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  if (selectedStatus.length != 0) {
    params.project_status = selectedStatus;
  }
  if (selectedPossessionStatus.length != 0) {
    params.possession_months = selectedPossessionStatus;
  }
  if (numericParts.length !== 0) {
    params.bhk = numericParts ? numericParts : 0;
    params.type = nonNumericParts;
  }
  if (selectedConf.length != 0) {
    params.configurations = selectedConf.toString().toLowerCase();
    // console.log("Configurations: ", selectedConf);
  }
  if (sortCriteria.length != 0) {
    params.orderby = sortCriteria;
  }
  if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
    let minPrice = Math.floor(priceRange[0]);
    let maxPrice = Math.ceil(priceRange[1]);
    params.minprice = minPrice;
    params.maxprice = maxPrice;
  }
  if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
    let minBuilt = Math.floor(builtUpArea[0]);
    let maxBuilt = Math.ceil(builtUpArea[1]);
    params.carpet_min = minBuilt;
    params.carpet_max = maxBuilt;
  }
  if(isIrfsMapPage == true){
    params.is_irfs = true;
  }
  const handleSliderChange = (newRange) => {
    setPriceRange(newRange);
  };
  const handleSliderChangeArea = (newRangeArea) => {
    setBuiltRange(newRangeArea);
  };

  const debouncedHandleSliderChangeComplete = debounce((params) => {
    setAllParams1(params);
    getProjects(params, 0);
  }, 1000);
  const debouncedHandleSliderChangeCompleteArea = debounce((params) => {
    setAllParams1(params);
    getProjects(params, 0);
  }, 1000);

  const handleSliderChangeComplete = (params) => {
    if (locality_name?.length != 0 && locality_name !== undefined && locality_name !== '' && locality_name !== null) {
      params.locality_name = locality_name;
      params.searchtype = 'locality_name';
    }
    if (selectedStatus.length != 0) {
      params.project_status = selectedStatus;
    }
    if (selectedPossessionStatus.length != 0) {
      params.possession_months = selectedPossessionStatus;
    }
    if (numericParts.length !== 0) {
      params.bhk = numericParts ? numericParts : 0;
      params.type = nonNumericParts;
    }
    if (selectedConf.length != 0) {
      params.configurations = selectedConf.toString().toLowerCase();
    }
    if (sortCriteria.length != 0) {
      params.orderby = sortCriteria;
    }

    if(isIrfsMapPage == true){
      params.is_irfs = true;
    }

    const minPrice = Math.floor(params[0]);
    const maxPrice = Math.ceil(params[1]);
    const priceparams = { ...params, minprice: minPrice, maxprice: maxPrice };
    delete priceparams[0];
    delete priceparams[1];
    let paramsURL = new URLSearchParams(location.search);
    paramsURL.set('minprice', minPrice);
    paramsURL.set('maxprice', maxPrice);
    location.search = paramsURL.toString();
    const newUrl = `${currentUrl}?${location.search}`;
    navigate(newUrl, { replace: true });
    debouncedHandleSliderChangeComplete(priceparams);
  };
  const handleSliderChangeCompleteArea = (params) => {
    if (locality_name?.length != 0 && locality_name !== undefined && locality_name !== '' && locality_name !== null) {
      params.locality_name = locality_name;
      params.searchtype = 'locality_name';
    }
    if (selectedStatus.length != 0) {
      params.project_status = selectedStatus;
    }
    if (selectedPossessionStatus.length != 0) {
      params.possession_months = selectedPossessionStatus;
    }
    if (numericParts.length !== 0) {
      params.bhk = numericParts ? numericParts : 0;
      params.type = nonNumericParts;
    }
    if (selectedConf.length != 0) {
      params.configurations = selectedConf.toString().toLowerCase();
    }
    if (sortCriteria.length != 0) {
      params.orderby = sortCriteria;
    }
    if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
      let minPrice = Math.floor(priceRange[0]);
      let maxPrice = Math.ceil(priceRange[1]);
      params.minprice = minPrice;
      params.maxprice = maxPrice;
    }
    const minBuilt = Math.floor(params[0]);
    const maxBuilt = Math.ceil(params[1]);
    const builtparams = { ...params, carpet_min: minBuilt, carpet_max: maxBuilt };
    delete builtparams[0];
    delete builtparams[1];
    let paramsURL = new URLSearchParams(location.search);
    paramsURL.set('carpet_min', minBuilt);
    paramsURL.set('carpet_max', maxBuilt);
    location.search = paramsURL.toString();
    // console.log('New Budget Price :- ', location.search);
    const newUrl = `${currentUrl}?${location.search}`;
    navigate(newUrl, { replace: true });
    debouncedHandleSliderChangeCompleteArea(builtparams);
  };

  useEffect(() => {
    // This effect only clears the paginated data
    setResponseData([]);
    setPage(1);
  }, [location.search, selectedStatus, selectedPossessionStatus, selectedBHK, sortCriteria, priceRange]); // Dependencies that trigger data reset
  useEffect(() => {
    setTimeout(async () => {
      if (projects.length === 0) {
        // getProjectStatusFunc();
        getProjectBhkTypeFunc();
        if(isIrfsMapPage == true){
          params.is_irfs = true;
        }
        setAllParams1(params);
        getProjects(params, 0, map);
      }
    }, 10);
  }, [params, selectedStatus, selectedPossessionStatus, selectedBHK, sortCriteria, map]);

  const getProjects = async (paramsData, scrollpage, newMap) => {
    if (paramsData === undefined || paramsData === "") {
      paramsData = getAllParams1;
    }
    let result = {};
    try {
      if (flagPage === false || scrollpage === 0) {
        scrollpage = 0;
        newPage = 1;
        setPage(newPage);
        paramsData.page = newPage;
      } else {
        scrollpage = 1;
        newPage = page + 1;
        setPage(newPage);
        paramsData.page = newPage;
      }
      const path = location.pathname.split("/");
      const seoparam = path[path.length - 1].split("-");
      const seobedroom = seoparam[0];
      const seocity = seoparam[seoparam.length - 1];
      // SEO URL like: /2-bhk-flats-for-sale-in-wakad-pune
      const seolocality = seoparam[6];
      if (seoparam.length === 8 && seoparam[1] === 'bhk') {
        paramsData.city = seocity;
        paramsData.locality_name = seolocality;
        paramsData.bhk = seobedroom;
        paramsData.type = 'bhk';
        paramsData.searchtype = 'locality_name';
      }
      // SEO URL like: /2-bhk-flats-for-sale-in-pune
      if (seoparam.length === 7 && seoparam[1] === 'bhk') {
        paramsData.city = seocity;
        paramsData.bhk = seobedroom;
        paramsData.type = 'bhk';
      }
      // SEO URL like: /property-in-pune
      if (seoparam.length === 3 && seoparam[0] === 'property') {
        paramsData.city = seocity;
      }

      if(isIrfsMapPage == true){
        paramsData.is_irfs = true;
      }
      paramsData.per_page = initialDisplayLimit;
      result = await executeLaravelFrontAPI('projects', { ...paramsData }, 'GET');
      const newItems = result.data.data;
      setResponseData(prevProjects => {
        const addedProjectIds = new Set(prevProjects.map(p => p.id));
        newItems.forEach(project => {
          if (!addedProjectIds.has(project.id)) {
            // Check if map is not null before calling addMarker
            if (map !== null) {
              addMarker(project, map);
            } else {
              console.error('Map object is null, cannot add marker.');
            }
          }
        });
        if (scrollpage === 1) {
          setPage(page + 1);
          return [...prevProjects, ...newItems];
        }
        if (scrollpage === 0) {
          setPage(1);
          return [...newItems];
        }
      });
      setTotalItems(result.data.total);
      setHasMore(newItems.length === initialDisplayLimit);
      flagPage = true;
      // newItems.forEach(project => addMarker(project));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      // setLoading(false);
      if (result.data?.total === 0) {
        setNoDataFound(true);
        setResponseData([]);
        setPage(1);
      }
    }
  };

  const fetchProjectsInBounds = async (newMap) => {
    let result = {};
    try {
      if (!newMap) {
        console.error('Map instance is null or undefined');
        return;
      }
      const bounds = newMap.getBounds();
      const sw = bounds.getSouthWest();
      const ne = bounds.getNorthEast();
      const per_page = 10;
      const params = getAllParams(location);
      if (locality_name?.length != 0 && locality_name !== undefined && locality_name !== '' && locality_name !== null) {
          params.locality_name = locality_name;
          fetchCoordinates(locality_name);
        params.searchtype = 'locality_name';
      }
      if (selectedStatus.length !== 0) {
        params.project_status = selectedStatus;
      }
      if (selectedPossessionStatus.length !== 0) {
        params.possession_months = selectedPossessionStatus;
      }
      if (numericParts.length !== 0) {
        params.bhk = numericParts ? numericParts : 0;
        params.type = nonNumericParts;
      }
      if (selectedConf.length !== 0) {
        params.configurations = selectedConf.toString().toLowerCase();
      }
      if (sortCriteria.length !== 0) {
        params.orderby = sortCriteria;
      }
      if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
        let minPrice = Math.floor(priceRange[0]);
        let maxPrice = Math.ceil(priceRange[1]);
        params.minprice = minPrice;
        params.maxprice = maxPrice;
      }
      if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
        let minBuilt = Math.floor(builtUpArea[0]);
        let maxBuilt = Math.ceil(builtUpArea[1]);
        params.carpet_min = minBuilt;
        params.carpet_max = maxBuilt;
      }
      if(isIrfsMapPage == true){
        params.is_irfs = true;
      }
      params.sw_lat = sw.lat;
      params.sw_lng = sw.lng;
      params.ne_lat = ne.lat;
      params.ne_lng = ne.lng;
      params.per_page = initialDisplayLimit;
      // const paramsData = {
      //   ...params,
      //   sw_lat: sw.lat,
      //   sw_lng: sw.lng,
      //   ne_lat: ne.lat,
      //   ne_lng: ne.lng,
      //   per_page: initialDisplayLimit,
      // };
     
      result = await executeLaravelFrontAPI(`projects`, params, 'GET');
      const newItems = result.data.data;
      mapRef.current.eachLayer(layer => {
        if (layer instanceof L.Marker) {
          mapRef.current.removeLayer(layer);
        }
      });
      setParams(params);
      // // Update the state with new projects
      setResponseData(newItems);

      // // Add new markers to the map
      newItems.forEach(project => {
        addMarker(project, newMap);
      });
      setTotalItems(result.data.total);
      setHasMore(newItems.length === initialDisplayLimit);
      setLoading(false);
      // window.scrollTo(0, 0);
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      // setLoading(false);
      if (result.data?.total === 0) {
        setNoDataFound(true);
        setResponseData([]);
        setPage(1);
      }
    }
  };
  {/* Start Project Status Sort Function */ }
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortOrderBHK, setSortOrderBHK] = useState('asc');
  // const [sortOrderConf, setSortOrderConf] = useState('asc');

  const statusOrder = {
    'New Launch': 1,
    'Under Construction': 2,
    'Ready To Move': 3,
    // 'Lunch': 4,
    // 'ongoing': 5,
    // 'prelaunch': 6,
  };
  const toggleStatus = (status) => {
    setSelectedStatus((prevStatus) => {
      const newStatus = prevStatus.includes(status)
        ? prevStatus.filter(item => item !== status) // Remove if already selected
        : [...prevStatus, status]; // Add if not already selected
      // Update params and fetch projects
      const params = getAllParams(location);
      if (locality_name?.length != 0 && locality_name !== undefined && locality_name !== '' && locality_name !== null) {
        params.locality_name = locality_name;
        params.searchtype = 'locality_name';
      }
      if (newStatus.length !== 0) {
        params.project_status = newStatus;
      }
      if (numericParts.length !== 0) {
        params.bhk = numericParts ? numericParts : 0;
        params.type = nonNumericParts;
      }
      if (selectedConf.length !== 0) {
        params.configurations = selectedConf.toString().toLowerCase();
      }
      if (sortCriteria.length !== 0) {
        params.orderby = sortCriteria;
      }
      if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
        let minPrice = Math.floor(priceRange[0]);
        let maxPrice = Math.ceil(priceRange[1]);
        params.minprice = minPrice;
        params.maxprice = maxPrice;
      }
      if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
        let minBuilt = Math.floor(builtUpArea[0]);
        let maxBuilt = Math.ceil(builtUpArea[1]);
        params.carpet_min = minBuilt;
        params.carpet_max = maxBuilt;
      }
      if(isIrfsMapPage == true){
        params.is_irfs = true;
      }
      let paramsURL = new URLSearchParams(location.search);
      paramsURL.set('project_status', newStatus);
      location.search = paramsURL.toString();
      console.log('New Project Status :- ', location.search);
      const newUrl = `${currentUrl}?${location.search}`;
      navigate(newUrl, { replace: true });
      setCurrentPage(0);
      setPage(1);

      params.page = 1;
      getProjects(params, 0);

      return newStatus; // Return the updated state value
    });
  };

  const togglePossessionStatus = (status) => {
    // console.log("status", status);
    // const newStatus = parseStatus(status);
    // console.log("statuschange", newStatus);
    setSelectedPossessionStatus((prevStatus) => {
      // console.log("prevStatus", prevStatus);
      const newStatus = prevStatus.includes(status)
        ? [] // Remove if already selected, resulting in an empty array
        : [status]; // Set as the only selected value

      // to return old exact array
      // const newStatus = prevStatus.includes(status)
      //     ? prevStatus.filter(item => item !== status) // Remove if already selected
      //     : [...prevStatus, status]; // Add if not already selected
      // Update params and fetch projects
      const params = getAllParams(location);
      if (locality_name?.length != 0 && locality_name !== undefined && locality_name !== '' && locality_name !== null) {
          params.locality_name = locality_name;
        params.searchtype = 'locality_name';
      }
      if (newStatus.length !== 0) {
        params.possession_months = newStatus;
      }
      if (numericParts.length !== 0) {
        params.bhk = numericParts ? numericParts : 0;
        params.type = nonNumericParts;
      }
      if (selectedConf.length !== 0) {
        params.configurations = selectedConf.toString().toLowerCase();
      }
      if (sortCriteria.length !== 0) {
        params.orderby = sortCriteria;
      }
      if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
        let minPrice = Math.floor(priceRange[0]);
        let maxPrice = Math.ceil(priceRange[1]);
        params.minprice = minPrice;
        params.maxprice = maxPrice;
      }
      if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
        let minBuilt = Math.floor(builtUpArea[0]);
        let maxBuilt = Math.ceil(builtUpArea[1]);
        params.carpet_min = minBuilt;
        params.carpet_max = maxBuilt;
      }

      if(isIrfsMapPage == true){
        params.is_irfs = true;
      }
      let paramsURL = new URLSearchParams(location.search);
      // calculateMonths(newStatus);
      // console.log("adaaarshh--"+newStatus);
      paramsURL.set('possession_months', newStatus);
      if (newStatus.length === 0) {
        paramsURL.delete('possession_months');
      }
      location.search = paramsURL.toString();
      // console.log('New Possession Status :- ', location.search);
      const newUrl = `${currentUrl}?${location.search}`;
      navigate(newUrl, { replace: true });
      setCurrentPage(0);
      setPage(1);

      params.page = 1;
      // getProjects(params, 0);

      return newStatus; // Return the updated state value
    });
  };

  function getSortProjectFilter(sortCriteria) {
    const params = getAllParams(location);
    if (locality_name?.length != 0 && locality_name !== undefined && locality_name !== '' && locality_name !== null) {
        params.locality_name = locality_name;
      params.searchtype = 'locality_name';
    }
    if (selectedStatus.length != 0) {
      params.project_status = selectedStatus;
    }
    if (selectedPossessionStatus.length != 0) {
      params.possession_months = selectedPossessionStatus;
    }
    if (numericParts.length !== 0) {
      params.bhk = numericParts ? numericParts : 0;
      params.type = nonNumericParts;
    }
    if (selectedConf.length != 0) {
      params.configurations = selectedConf.toString().toLowerCase();
    }
    if (sortCriteria.length != 0) {
      params.orderby = sortCriteria;
    }
    if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
      let minPrice = Math.floor(priceRange[0]);
      let maxPrice = Math.ceil(priceRange[1]);
      params.minprice = minPrice;
      params.maxprice = maxPrice;
    }
    if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
      let minBuilt = Math.floor(builtUpArea[0]);
      let maxBuilt = Math.ceil(builtUpArea[1]);
      params.carpet_min = minBuilt;
      params.carpet_max = maxBuilt;
    }
    if(isIrfsMapPage == true){
      params.is_irfs = true;
    }
    let paramsURL = new URLSearchParams(location.search);
    paramsURL.set('orderby', sortCriteria);
    location.search = paramsURL.toString();
    const newUrl = `${currentUrl}?${location.search}`;
    navigate(newUrl, { replace: true });
    createGa4Event('sort_filter', 'Sort Filter', sortCriteria)
    setSortCriteria(sortCriteria);
    getProjects(params, 0);
  }

  const sortProjectsByBhkType = (status) => {
    const order = sortOrderBHK === 'asc' ? 'desc' : 'asc';
    const sorted = [...projects].sort((a, b) => {
      const orderFactor = order === 'asc' ? 1 : -1;
      return orderFactor * (statusOrder[a.configurations.name] - statusOrder[b.configurations.name]);
    });
    setSortOrderBHK(order);
    setSelectedTab(status);
    setCurrentPage(0);
  };

  function getSortProjectFilter(sortCriteria) {
    const params = getAllParams(location);
    if (locality_name?.length != 0 && locality_name !== undefined && locality_name !== '' && locality_name !== null) {
        params.locality_name = locality_name;
      params.searchtype = 'locality_name';
    }
    if (selectedStatus.length != 0) {
      params.project_status = selectedStatus;
    }
    if (selectedPossessionStatus.length != 0) {
      params.possession_months = selectedPossessionStatus;
    }
    if (numericParts.length !== 0) {
      params.bhk = numericParts ? numericParts : 0;
      params.type = nonNumericParts;
    }
    if (selectedConf.length != 0) {
      params.configurations = selectedConf.toString().toLowerCase();
    }
    if (sortCriteria.length != 0) {
      params.orderby = sortCriteria;
    }
    if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
      let minPrice = Math.floor(priceRange[0]);
      let maxPrice = Math.ceil(priceRange[1]);
      params.minprice = minPrice;
      params.maxprice = maxPrice;
    }
    if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
      let minBuilt = Math.floor(builtUpArea[0]);
      let maxBuilt = Math.ceil(builtUpArea[1]);
      params.carpet_min = minBuilt;
      params.carpet_max = maxBuilt;
    }
    let paramsURL = new URLSearchParams(location.search);
    paramsURL.set('orderby', sortCriteria);
    location.search = paramsURL.toString();
    const newUrl = `${currentUrl}?${location.search}`;
    navigate(newUrl, { replace: true });
    createGa4Event('sort_filter', 'Sort Filter', sortCriteria)
    setSortCriteria(sortCriteria);
    getProjects(params, 0);
  }
  const toggleBhkType = (bhkType) => {
    setSelectedBHK((prevBhkType) => {
      const newBhkType = prevBhkType.includes(bhkType)
        ? prevBhkType.filter(item => item !== bhkType) // Remove if already selected
        : [...prevBhkType, bhkType]; // Add if not already selected

      console.log("Selected BHK Types:------", newBhkType);

      // Check if the newBhkType array is empty to display the "Data not found" message
      if (newBhkType.length === 0) {
        console.log("Data not found"); // Show message or handle the scenario
      }

      // Update params and fetch projects
      const params = getAllParams(location);

      if (locality_name?.length != 0 && locality_name !== undefined && locality_name !== '' && locality_name !== null) {
          params.locality_name = locality_name;
          console.log(locality_name);
        params.searchtype = 'locality_name';
      }
      if (selectedStatus.length !== 0) {
        params.project_status = selectedStatus;
      }
      if (selectedPossessionStatus.length != 0) {
        params.possession_months = selectedPossessionStatus;
      }
      getNumericParts(newBhkType.map(type => type.match(/^\d*\.?\d*/)[0] === "" ? '0' : type.match(/^\d*\.?\d*/)[0]));
      getNonNumericParts(newBhkType.map(type => type.match(/[a-zA-Z]+$/)[0].toLowerCase()));

      const numericPartsNew = newBhkType.map(type => type.match(/^\d*\.?\d*/)[0] === "" ? '0' : type.match(/^\d*\.?\d*/)[0]);
      const nonNumericPartsNew = newBhkType.map(type => type.match(/[a-zA-Z]+$/)[0].toLowerCase());
      if (numericPartsNew.length !== 0) {
        params.bhk = numericPartsNew ? numericPartsNew : 0;
        params.type = nonNumericPartsNew;
      }
      if (selectedConf.length !== 0) {
        params.configurations = selectedConf.toString().toLowerCase();
      }
      if (sortCriteria.length !== 0) {
        params.orderby = sortCriteria;
      }
      if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
        let minPrice = Math.floor(priceRange[0]);
        let maxPrice = Math.ceil(priceRange[1]);
        params.minprice = minPrice;
        params.maxprice = maxPrice;
      }
      if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
        let minBuilt = Math.floor(builtUpArea[0]);
        let maxBuilt = Math.ceil(builtUpArea[1]);
        params.carpet_min = minBuilt;
        params.carpet_max = maxBuilt;
      }
      let paramsURL = new URLSearchParams(location.search);
      let newBhkTypeArray = Array.isArray(newBhkType) ? newBhkType : newBhkType.split(',');
      paramsURL.set('configuration', newBhkTypeArray);
      let numericPartsNewArray = Array.isArray(numericPartsNew) ? numericPartsNew : numericPartsNew.split(',');
      paramsURL.set('bhk', numericPartsNewArray);
      let nonNumericPartsNewArray = Array.isArray(nonNumericPartsNew) ? nonNumericPartsNew : nonNumericPartsNew.split(',');
      paramsURL.set('type', nonNumericPartsNewArray);
      location.search = paramsURL.toString();
      const newUrl = `${currentUrl}?${location.search}`;
      navigate(newUrl, { replace: true });

      setSelectedTab(bhkType);
      setCurrentPage(0);
      setPage(1);
      getProjects(params, 0);

      return newBhkType;
    });
  };
  const getProjectBhkTypeFunc = async () => {
    try {
      const result = await executeLaravelFrontAPI('bhkconfigurations', "", 'GET');
      getProjectBhkType(result.data);
    } catch (error) {
      console.error('Error parsing JSON:', error);
    } finally {

    }
  };
  const filterByBhkType = (result) => {
    // return result.filter(item => !isNaN(item.name.charAt(0))) || [];
  };

  //const filteredByBhk = filterByBhkType(getProjectBhkTypeData);
  const filteredByBhk = getProjectBhkTypeData;
  const toggleShowAllBhk = () => {
    setShowAllBhk(!showAllBhk);
  };

  const [selectedTab, setSelectedTab] = useState(null);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleFilter = (newRange) => {
    const params = getAllParams(location);
    params.minprice = newRange[0];
    params.maxprice = newRange[1];
    setPage(1);
    getProjects(params);
    createGa4Event('filter_budget', 'Filter Budget', newRange[0] + "-" + newRange[1])
  };
  const handleFilterArea = (newRange) => {
    const params = getAllParams(location);
    params.carpet_min = newRange[0];
    params.carpet_max = newRange[1];
    setPage(1);
    getProjects(params);
    createGa4Event('filter_builtup', 'Built Up Filter', `${newRange[0]} - ${newRange[1]}`);
  };
  const handleShowFilterSidebarBtn = () => {
    const rightSideDiv = document.querySelector('.filter-sidebar');
    rightSideDiv.classList.toggle('active');
  }
  const handleHideFilterSidebarBtn = () => {
    // const filterForm = document.querySelector('.filter-sidebar');
    // filterForm.reset();
    handleShowFilterSidebarBtn();
    // window.scrollTo(0, 0);
  }
  const handleShowSortSidebarBtn = () => {
    const sortSideBar = document.getElementById('sort-filter');
    sortSideBar.classList.toggle('active');
  }
  const handleHideSortSidebarBtn = () => {
    const sortSideBar = document.getElementById('sort-filter');
    sortSideBar.classList.remove('active');
  }
  const showMapView = () => {
    setLayerOff(true);
    setMapviewBtn(false);
    setListviewBtn(true);
  }
  const showListView = () => {
    setLayerOff(false);
    setMapviewBtn(true);
    setListviewBtn(false);
  }

  useEffect(() => {
    if (hoveredProject) {
      const projectMarker = markers.find(({ projectName }) => projectName === hoveredProject);
      if (projectMarker) {
        showCustomPopup(projectMarker.marker, projectMarker.project, 'right');
      }
    } else {
      map && map.closePopup();
    }
  }, [hoveredProject, markers, map]);

  const addMarker = (project, map) => {
    // if (!map) {
    //   console.error('Map instance is not defined');
    //   return;
    // }
    const lat = Number(project.address.latitude);
    const lng = Number(project.address.longitude);
    if (isNaN(lat) || isNaN(lng)) {
      console.error('Invalid latitude or longitude:', project);
      return;
    }
    const customIcon = L.divIcon({
      className: 'custom-icon',
      html: '<div class="icon-dot"></div>',
      iconSize: [10, 10],
      iconAnchor: [5, 5],
    });
    const css = `
    .leaflet-marker-icon{
      border-radius: 50%;
    }
    .custom-icon .icon-dot {
      width: 8px;
      height: 8px;
      background-color: #082040;
      border-radius: 50%;
      transition: transform 0.7s ease-in-out;
    }
    .custom-icon:hover .icon-dot {
      width: 20px;
      height: 20px;
      background-color:rgba(8,32,64,0.5);
      position:relative;
      top:-6px;
      left:-6px;
      border-radius: 50%;
    }
  .leaflet-popup-tip-container {
          display: none;
      }
    .custom-icon:hover .icon-dot:after,
    .custom-icon.hover .icon-dot:after {
      content:'';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color:rgba(8,32,64,1);
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);
    }`;
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    head.appendChild(style);
    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));

    const marker = L.marker([lat, lng], { icon: customIcon }).addTo(map);
    marker.on('mouseover', () => {
      setHoveredProject(project.name);
      setCurrentPopupProject(project);
      setPopupVisible(true);
      marker.getElement().classList.add('hover');
    });
    marker.on('click', () => {
      setHoveredProject(project.name);
      setCurrentPopupProject(project);
      setPopupVisible(true);
      marker.getElement().classList.add('hover');
    });
    marker.on('mouseout', (e) => {
      if (!e.relatedTarget || !e.relatedTarget.closest('.leaflet-popup')) {
        setHoveredProject(null);
        marker.getElement().classList.remove('hover');
      }
    });
    setMarkers((prevMarkers) => [...prevMarkers, { projectName: project.name, marker, project }]);
  };

  const clearMarkers = () => {
    markers.forEach(({ marker }) => {
      map.removeLayer(marker);
    });
    setMarkers([]);
  };

  const handleProjectMouseOver = (projectName) => {
    setHoveredProject(projectName);
  };

  const handleProjectMouseOut = () => {
    setHoveredProject(null);
  };

  const showCustomPopup = (marker, project, position = 'right') => {
    const imageUrl = project.gallery && Array.isArray(project.gallery) && project.gallery.length > 0 ? encodeURI(`${s3url}/project-assets${project.gallery[0].url}`) : defaultGallery;
    const projectUrl = `${appAbsPath}/project/${location.slug}`;
    const minMaxPrice = getMinMaxPrice(project);
    const minPrice = minMaxPrice && minMaxPrice.minPrice !== null ? formatNumber(minMaxPrice.minPrice.toFixed(2)) : null;
    const maxPrice = minMaxPrice && minMaxPrice.maxPrice !== null ? formatNumber(minMaxPrice.maxPrice.toFixed(2)) : '';
    const priceRange = minPrice !== null && minPrice !== 0 ? `₹${minPrice} - ${maxPrice}` : '';

    const minMaxCarpets = getMinMaxCarpets(project);
    const minCarpet = minMaxCarpets.minCarpet.toFixed(0);
    const maxCarpet = minMaxCarpets.maxCarpet.toFixed(0);
    {/* <h4 class='mb-2 mt-2 fw-bold'>${priceRange}</h4>
            ${getSortBHKConf(project)}, ${minCarpet} - ${maxCarpet} sq.ft. */}
    const popupContent = `
      <div class='inner-map-popup' id=${project.id}>
        <a href=${projectUrl} class='text-decoration-none'>
          <img src=${imageUrl} class='project-img' />
          <div class='leaflet-popup-content'>
            
            <a href='${appAbsPath}/project/${project.slug}' onClick='createGa4Event("project_view", "Project View", "${project.name}")' class='text-secondary text-capitalize text-decoration-none'>
              <h6 class="m-0">${project.name}</h6>
            </a>
            <p class='mb-0 developer-name'>By <span>${project.developer.name}</span>, ${project?.address?.location}, ${project?.address?.city}</p>
            <div class="map-card-btn-wrapper">
              <a href='https://www.google.com/maps/dir/?api=1&destination=${project?.address?.latitude},${project?.address?.longitude}' target="_blank" class='btn btn-primary map-card-btn'>
                <img src=${direction} class='direction-img' width="30px" height="30px"/>
              </a>
            </div>
          </div>
        </a>
      </div>
    `;
    const markerLatLng = marker.getLatLng();
    const mapBounds = map.getBounds();
    let offset = [0, 0];

    if (markerLatLng.lat > mapBounds.getCenter().lat) {
      offset[1] = -115;
    } else {
      offset[1] = 115;
    }

    if (markerLatLng.lng > mapBounds.getCenter().lng) {
      offset[0] = -120;
    } else {
      offset[0] = 120;
    }

    const popup = L.popup({
      autoPan: false,
      keepInView: true,
      closeButton: true,
      className: 'custom-popup',
      // offset: offset
    })
      .setLatLng(marker.getLatLng())
      .setContent(popupContent)
      .openOn(map);
  };
  const handleScroll = () => {
    if (window.scrollY > lastScrollY) {
      // Scrolling down
      setVisible(false);
    } else {
      // Scrolling up
      setVisible(true);
    }
    setLastScrollY(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);


  return (
    <main className='plp-page pb-5 pt-lg-5 pt-2 mt-5 mt-md-5 bg-white test map-main-wrapper'>
      <section className="pt-0 mt-5 pt-md-4 mob-map-wrapper">
        <div className="px-4">
          <div className="row filter-sticky-map  d-none d-lg-flex">
            <div className='col-8'>
              <NewFilterSidebar
                selectedStatus={selectedStatus} toggleStatus={toggleStatus}
                selectedPossessionStatus={selectedPossessionStatus} togglePossessionStatus={togglePossessionStatus}
                // sortProjectsByStatus={sortProjectsByStatus}
                filteredByBhk={filteredByBhk} initialDisplayLimit={initialDisplayLimit} showAllBhk={showAllBhk} toggleShowAllBhk={toggleShowAllBhk} selectedBHK={selectedBHK}
                toggleBhkType={toggleBhkType} sortProjectsByBhkType={sortProjectsByBhkType} setSelectedTab={setSelectedTab}
                priceRange={priceRange} handleSliderChange={handleSliderChange} handleSliderChangeComplete={handleSliderChangeComplete} builtUpArea={builtUpArea} handleSliderChangeArea={handleSliderChangeArea} handleSliderChangeCompleteArea={handleSliderChangeCompleteArea}
                getProjectstatusData={getProjectstatusData}
              />
            </div>
            <div className='col-4'>
              <div className="d-flex row">
                <div className='col-lg-12 col-12 row'>
                  <div className='col-12 mt-2 text-end d-inline-flex align-items-center justify-content-end pe-0 text-end'>
                    <span className='me-2 d-none d-md-block sort-by'>Sort by: </span>
                    <select className="form-select form-select-sm  form-select-sm d-none d-md-block rounded-pill" value={sortCriteria} onChange={(e) => getSortProjectFilter(e.target.value)} style={{ "width": "200px" }}>
                      <option value="relevance">Relevance</option>
                      <option value="recentlyLaunched">Recently Launched</option>
                      <option value="priceLowToHigh">Price: Low to High</option>
                      <option value="priceHighToLow">Price: High to Low</option>
                      <option value="sqftLowToHigh">Sq. Ft.: Low to High</option>
                      <option value="sqftHighToLow">Sq. Ft.: High to Low</option>
                      <option value="possessionDate">Possession Date (Recent First)</option>
                      {/* <option value="updatedat">Latest Updated</option> */}
                    </select>

                    <a href={`${appAbsPath}/projects${location.search}`} className={` ms-2 text-decoration-underline border p-1  rounded-pill`} onClick={() => createGa4Event('On Click', 'List View', 'List View')}>
                      <Icon icon="uim:list-ul" style={{ "color": "rgb(107, 107, 107)", "width": "40px", "height": "25px" }} />
                    </a>
                    <a className={`text-primary ms-2 text-decoration-underline border-primary p-1 rounded-pill`} onClick={() => createGa4Event('On Click', 'Map View', 'Map View')}>
                      <Icon icon="uiw:map" style={{ "color": "", "width": "40px", "height": "25px" }} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row col-reverse">
            <div id="projects" className='col-12 col-lg-7'>
              <div className='row'>
                <div className='col-lg-12 col-12 pb-3'>
                  {projects ? (
                    projects.length === 0 && loading ? (
                      (noDataFound ? (
                        <></>
                      ) : (
                        <Placeholder animation="wave" >
                          <Placeholder xs={12} className="custom-loading-skeleton" />
                        </Placeholder>
                      ))
                    ) : (
                      <h1 className="fs-6 mt-2 fw-bold"><span className="text-primary">Showing</span> <span id="search-count" className="text-primary">{totalItems} </span>
                        Properties
                      </h1>
                    )
                  ) : (
                    <Placeholder animation="wave" >
                      <Placeholder xs={12} className="custom-loading-skeleton" />
                    </Placeholder>
                  )}
                </div>
                <div className='col-12 col-md-12 mt-0 mt-md-0'>
                  {projects ? (
                    projects.length === 0 && loading ? (
                      (noDataFound ? (
                        <div className="text-center mt-5">
                          <h2>No Projects Found</h2>
                        </div>
                      ) : (
                        <Loader />
                      ))
                    ) : (
                      <div className="mam-project-listing-wrapper">
                        <Suspense fallback={<Loader />}>
                          <InfiniteScroll
                            dataLength={projects.length}
                            scrollableTarget="'top-projects-slider"
                            next={() => getProjects(params, page + 1, map)}
                            hasMore={hasMore}
                            loader={<h4><span className="custom-loader m-auto"></span></h4>}
                            endMessage={<p className='text-center'>You've reached the end of the list. <br /> Explore more properties by adjusting your filters or refining your search.</p>}
                          >
                            <div className='top-projects-slider mapView'>
                              <div className="row mx-0 gy-4">
                                {projects.map((project, index) => (

                                  <div class='col-12 col-md-6'>
                                    <ProjectCard
                                      key={index}
                                      project={project}
                                    // isWishlistActive={isWishlistActive}
                                    />
                                  </div>


                                  // <div className="tabcontent rounded-8 mb-3 col-lg-6 col-12 px-0 px-lg-2 new-map-view-card" id={project.id} key={index} onMouseOver={() => handleProjectMouseOver(project.name)}
                                  //   onMouseOut={() => handleProjectMouseOut(project.name)}>
                                  //   <div className="px-0 rounded pe-md-0 p-0 plp-card-main">
                                  //     <div className="image-side position-relative">
                                  //       {project && project.gallery && Array.isArray(project.gallery) && project.gallery.length > 0 ? (
                                  //         <Carousel data-bs-theme="light">
                                  //           {project.gallery.map((image, index) => (
                                  //             <Carousel.Item key={index}>
                                  //               <Link to={`${appAbsPath}/project/${project.slug}`} onClick={() => { createGa4Event("project_view", 'Project View', `${project.name}`) }} className='text-decoration-none'>
                                  //                 {/* <div className='lazyload-placeholder project-img' style={{ backgroundImage: `url(${s3url}/project-assets${image.url})` }}></div> */}
                                  //                 <img src={`${s3url}/project-assets${image.url}`} />
                                  //               </Link>
                                  //             </Carousel.Item>
                                  //           ))}
                                  //         </Carousel>
                                  //       ) : (
                                  //         <>
                                  //           <div className='lazyload-placeholder project-img' style={{ backgroundImage: `url(${defaultGallery})` }}></div>
                                  //         </>
                                  //       )}
                                  //       <WishListBtn projectId={project.id} loggeduserdetails={parsedUserData} color={"#fff"} width={"30px"} height={"30px"} handleNotLogin />
                                  //       <div className="position-absolute zindex-10 top-0 bottom-0 start-0 m-3 top-picks">
                                  //         <div className="d-flex flex-column text-center">
                                  //           <span className="fw-bold"><Icon icon="mingcute:star-fill" className='mb-1' color={"#ffce31"} />


                                  //             {dateDiffInMonths(project.completion_timestamp, new Date()) <= 3 ? 'Ready To Move' : project.project_status == '' ? dateDiffInMonths(project.completion_timestamp, new Date()) > 3 && dateDiffInMonths(project.completion_timestamp, new Date()) < 24 ? 'Under Construction' : 'New Project' : project.project_status}
                                  //             {/* {project.project_status} */}
                                  //           </span>
                                  //           {/* <span className="ready-since text-white">(Since Jun, 2021)</span> */}
                                  //         </div>
                                  //       </div>
                                  //     </div>
                                  //     <div className="flex-grow-1 flex-basis-0 position-relative pe-0 pe-md-4 prjt-info-wrap">
                                  //       <div className="pt-2 position-relative p-3 info-box">
                                  //         <h4 className='mb-2 mt-2 fw-bold'>{getMinMaxPrice(project) && getMinMaxPrice(project).minPrice !== null && formatNumber((getMinMaxPrice(project).minPrice).toFixed(2)) !== 0
                                  //           ? <span>{`₹${formatNumber((getMinMaxPrice(project).minPrice).toFixed(2))} - ${getMinMaxPrice(project) && getMinMaxPrice(project).maxPrice !== null ? formatNumber((getMinMaxPrice(project).maxPrice).toFixed(2)) : ''}`}</span>
                                  //           : null
                                  //         }</h4>
                                  //         {getSortBHKConf(project)}, {(getMinMaxCarpets(project).minCarpet).toFixed(0)} - {(getMinMaxCarpets(project).maxCarpet).toFixed(0)} sq.ft.
                                  //         <Link to={`${appAbsPath}/project/${project.slug}`} onClick={() => { createGa4Event("project_view", 'Project View', `${project.name}`) }} className='text-secondary text-capitalize text-decoration-none' >
                                  //           <h6 className="m-0">{project.name}</h6>
                                  //         </Link>
                                  //         <p className='mb-0 developer-name'>By <span>{project.developer.name}</span>, {project?.address?.location}, {project?.address?.city}</p>
                                  //       </div>
                                  //     </div>
                                  //   </div>
                                  // </div>

                                ))}
                              </div>
                            </div>
                          </InfiniteScroll>
                        </Suspense>
                      </div>
                    )
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
            <div className={`col-12 col-lg-5 p-0 ${isLayerOff && "active"}`} id="sticky-map-rightsidebarwrap" style={{ height: 'calc(100vh - 160px)' }}>
              <div className='col-12 col-lg-5' id="sticky-map-rightsidebar"></div>
            </div>
          </div>
        </div>

      </section>
      {/* Mobile map view and List View */}
      <div className='mobile-map-list-view d-block d-md-none test'>
        {/* {isListBtn &&
          <a className={`text-primary ms-2 text-decoration-underline border p-1 border-primary rounded-pill listbtn`} onClick={() => showListView()}>
            <Icon icon="uim:list-ul" style={{ "color": "#43A66A", "width": "40px", "height": "25px" }} />
          </a>
        }
        {isMapBtn &&
          <a className={`text-primary ms-2 text-decoration-underline border p-1 border-primary rounded-pill mapbtn`} onClick={() => showMapView()}>
            <Icon icon="uiw:map" style={{ "color": "#43A66A", "width": "40px", "height": "25px" }} />
          </a>
        } */}
        <a href={`${appAbsPath}/projects${location.search}`} className={` ms-2 text-decoration-underline border p-1  rounded-pill`} onClick={() => createGa4Event('On Click', 'List View', 'List View')}>
          <Icon icon="uim:list-ul" style={{ "color": "rgb(107, 107, 107)", "width": "40px", "height": "25px" }} />
        </a>
        <a className={`text-primary ms-2 text-decoration-underline border-primary p-1 rounded-pill`} onClick={() => createGa4Event('On Click', 'Map View', 'Map View')}>
          <Icon icon="uiw:map" style={{ "color": "", "width": "40px", "height": "25px" }} />
        </a>
      </div>
      {/* Mobile Filters */}
      <div className={`d-block d-lg-none mobile-filter-wrap ${visible ? 'slide-up' : 'slide-down'}`}>
        <a className='filter-sidebar-sort' onClick={() => handleShowSortSidebarBtn()}><Icon icon="bx:sort" /> Sort</a>
        <a className='filter-sidebar-btn' onClick={() => handleShowFilterSidebarBtn()}><Icon icon="mage:filter" /> Filter</a>
      </div>
      <div className='d-block d-lg-none mobile-filter-wrap-popup' id="sort-filter">
        <div className='filter-sidebar-sort-popup'>
          <h4>Sort By: <a className='float-end' onClick={() => handleHideSortSidebarBtn()}><Icon icon="codicon:close" style={{ "color": "#6b6b6b" }} /></a></h4>
          <hr />
          <ul>
            <li onClick={() => { getSortProjectFilter("relevance"); handleHideSortSidebarBtn(); createGa4Event('On Click', 'Filters', 'Relevance') }}>
              <div className='d-flex justify-content-between'>
                Relevance
              </div>
            </li>
            <li onClick={() => { getSortProjectFilter("recentlyLaunched"); handleHideSortSidebarBtn(); }}>
              <div className='d-flex justify-content-between'>
                Recently Launched
              </div>
            </li>
            <li onClick={() => { getSortProjectFilter("priceLowToHigh"); handleHideSortSidebarBtn() }}>
              <div className='d-flex justify-content-between'>
                Price: Low to High
              </div>
            </li>
            <li onClick={() => { getSortProjectFilter("priceHighToLow"); handleHideSortSidebarBtn() }}>
              <div className='d-flex justify-content-between'>
                Price: High to Low
              </div>
            </li>
            <li onClick={() => { getSortProjectFilter("sqftLowToHigh"); handleHideSortSidebarBtn() }}>
              <div className='d-flex justify-content-between'>
                Sq. Ft.: Low to High
              </div>
            </li>
            <li onClick={() => { getSortProjectFilter("sqftHighToLow"); handleHideSortSidebarBtn() }}>
              <div className='d-flex justify-content-between'>
                Sq. Ft.: High to Low
              </div>
            </li>
            {/* <li onClick={() => { getSortProjectFilter("recentlyLaunched"); handleHideSortSidebarBtn() }}>
              <div className='d-flex justify-content-between'>
                Recently Launched
              </div>
            </li> */}
            <li onClick={() => { getSortProjectFilter("possessionDate"); handleHideSortSidebarBtn() }}>
              <div className='d-flex justify-content-between'>
                Possession Date (Recent First)
              </div>
            </li>
            {/* <li onClick={() => { getSortProjectFilter("updatedat"); handleHideSortSidebarBtn() }}>
              <div className='d-flex justify-content-between'>
                Latest Updated
              </div>
            </li> */}
          </ul>
        </div>
      </div>
      <div className='filter-sidebar d-block d-md-none'>
        <h4 className='mb-0'>Filter: <a className='float-end' onClick={() => handleHideFilterSidebarBtn()}><Icon icon="codicon:close" style={{ "color": "#6b6b6b" }} /></a></h4>
        <hr className='mt-0' />
        <Tab.Container id="left-tabs-example" defaultActiveKey="three">
          <Row className='align-items-stretch d-flex'>
            <Col sm={4} className='pe-0 align-items-stretch d-flex flex-column left-tab-container'>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="three">Project Status</Nav.Link>
                </Nav.Item>
                <Nav.Item className='flex-column d-flex'>
                  <Nav.Link eventKey="first">Configuration Type</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="five">Possession Status</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Budget</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="four">Carpet Area (Sq.Ft.)</Nav.Link>
                </Nav.Item>

              </Nav>
            </Col>
            <Col sm={8} className='ps-3'>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  {filteredByBhk && Array.isArray(filteredByBhk) && selectedBHK && Array.isArray(selectedBHK) && filteredByBhk.length > 0 && filteredByBhk.slice(0, showAllBhk ? filteredByBhk.length : initialDisplayLimit).map((BhkType, index) => (
                    BhkType !== "" && (
                      BhkType && BhkType.bedrooms && BhkType.type &&
                      <a key={index} className={`btn btn-outline-secondary me-2 mb-2 ${selectedBHK.includes(BhkType.bedrooms + " " + BhkType.type.toUpperCase()) ? 'active' : ''}`}
                        onClick={() => {
                          toggleBhkType(BhkType.bedrooms + " " + BhkType.type.toUpperCase());
                          sortProjectsByBhkType(selectedBHK);
                          setSelectedTab(BhkType.bedrooms + " " + BhkType.type.toUpperCase());
                          createGa4Event('filter_configuration_type', 'ConfigurationTypeFilter', BhkType.bedrooms + " " + BhkType.type.toUpperCase())
                        }}>
                        {BhkType.bedrooms} {BhkType.type.toUpperCase()}
                      </a>

                    )
                  ))}
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Slider
                    range
                    min={500000}
                    max={50000000}
                    value={priceRange}
                    onChange={handleSliderChange}
                    onChangeComplete={handleFilter}
                  />
                  <p className='d-flex justify-content-between'>
                    <span>{`₹${formatNumber(priceRange[0])}`}</span>
                    <span className='pe-md-0 pe-3'>{`₹${formatNumber(priceRange[1])}`}+</span>
                  </p>
                </Tab.Pane>
                <Tab.Pane eventKey="three">
                  {/* {getProjectstatusData.map((status, index) => (
                                    (status != "" && 
                                        <a
                                            key={index}
                                            className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes(status) ? 'active' : ''}`}
                                            onClick={() => {
                                            toggleStatus(status);
                                            sortProjectsByStatus(selectedStatus);
                                            }}
                                        >
                                        {status}
                                        </a>
                                    )
                                ))} */}
                  {/* <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("New Launch") ? 'active' : ''}`}
                    onClick={() => {
                      toggleStatus("New Launch");
                      // sortProjectsByStatus(selectedStatus);
                    }}>
                    New Launch
                  </a> */}
                  <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("New Project") ? 'active' : ''}`}
                    onClick={() => {
                      toggleStatus("New Project");
                      // sortProjectsByStatus(selectedStatus);
                    }}>
                    New Project
                  </a>
                  <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("Under Construction") ? 'active' : ''}`}
                    onClick={() => {
                      toggleStatus("Under Construction");
                      // sortProjectsByStatus(selectedStatus);
                    }}>
                    Under Construction
                  </a>
                  <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("Ready To Move") ? 'active' : ''}`}
                    onClick={() => {
                      toggleStatus("Ready To Move");
                      // sortProjectsByStatus(selectedStatus);
                    }}>
                    Ready To Move
                  </a>
                  <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("New Launch") ? 'active' : ''}`}
                    onClick={() => {
                      toggleStatus("New Launch");
                      // sortProjectsByStatus(selectedStatus);
                    }}>
                    New Launch
                  </a>
                  <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("Nearing Possession") ? 'active' : ''}`}
                    onClick={() => {
                      toggleStatus("Nearing Possession");
                      // sortProjectsByStatus(selectedStatus);
                    }}>
                    Nearing Possession
                  </a>
                </Tab.Pane>

                <Tab.Pane eventKey="five">
                  <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 3 ? 'active' : ''}`}
                    onClick={() => {
                      togglePossessionStatus('3');
                      // sortProjectsByStatus(selectedPossessionStatus);
                      createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 3 months')
                    }}>
                    In 3 months
                  </a>
                  <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 6 ? 'active' : ''}`}
                    onClick={() => {
                      togglePossessionStatus('6');
                      // setProjectStatusFilter("In 6 months");
                      // sortProjectsByStatus(selectedPossessionStatus);
                      createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 6 months')
                    }}>
                    In 6 months
                  </a>
                  <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 12 ? 'active' : ''}`}
                    onClick={() => {
                      togglePossessionStatus('12');
                      // setProjectStatusFilter("In 1 year");
                      // sortProjectsByStatus(selectedPossessionStatus);
                      createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 1 year')
                    }}>
                    In 1 year
                  </a>
                  <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 24 ? 'active' : ''}`}
                    onClick={() => {
                      togglePossessionStatus('24');
                      // setProjectStatusFilter("In 2 years");
                      // sortProjectsByStatus(selectedPossessionStatus);
                      createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 2 years')
                    }}>
                    In 2 years
                  </a>
                  <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 36 ? 'active' : ''}`}
                    onClick={() => {
                      togglePossessionStatus('36');
                      // setProjectStatusFilter("In 3 years");
                      // sortProjectsByStatus(selectedPossessionStatus);
                      createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 3 years')
                    }}>
                    In 3 years
                  </a>
                  <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 60 ? 'active' : ''}`}
                    onClick={() => {
                      togglePossessionStatus('60');
                      // setProjectStatusFilter("In 5 years");
                      // sortProjectsByStatus(selectedPossessionStatus);
                      createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 5 years')
                    }}>
                    In 5 years
                  </a>
                  <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 84 ? 'active' : ''}`}
                    onClick={() => {
                      togglePossessionStatus('84');
                      // setProjectStatusFilter("In 7 years");
                      // sortProjectsByStatus(selectedPossessionStatus);
                      createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 7 years')
                    }}>
                    In 7 years
                  </a>
                </Tab.Pane>
                <Tab.Pane eventKey="four">
                  <Slider
                    range
                    min={100}
                    max={5000}
                    value={builtUpArea}
                    onChange={handleSliderChangeArea}
                    onChangeComplete={handleFilterArea}
                  />
                  <p className='d-flex justify-content-between'>
                    <span>{`${builtUpArea[0]} Sq.Ft.`}</span>
                    <span className='pe-md-0 pe-3'>{`${builtUpArea[1]} Sq.Ft.`} {builtUpArea[1] === 5000 && '+'}</span>
                  </p>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
        <button className="btn btn-primary w-100 theme-btn filter-apply-btn" onClick={() => handleHideFilterSidebarBtn()}>Apply</button>
      </div>
    </main>
  );
};

export default NewMapView;
