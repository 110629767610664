import React, { useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import Slider from 'rc-slider'; // Correct import from 'rc-slider'
import 'rc-slider/assets/index.css'; // Ensures slider styles are applied
import { formatNumber } from '../../Admin/Utils';
// import $ from 'jquery';
import { createGa4Event, isMobileDevice } from '../../Admin/Utils';
import { setProjectStatusFilter, setProjectConfigurationFilter, setProjectMaxBudgetFilter } from '../../Admin/UserActivatyTracker';
// import StickyBox from "react-sticky-box";

const isMobile = isMobileDevice();
const NewFilterSidebar = ({
  selectedStatus, toggleStatus, //sortProjectsByStatus,
  togglePossessionStatus, selectedPossessionStatus,
  filteredByBhk, initialDisplayLimit, showAllBhk, toggleShowAllBhk, selectedBHK,
  toggleBhkType, sortProjectsByBhkType, setSelectedTab,
  priceRange,builtUpArea, handleSliderChange, handleSliderChangeComplete,handleSliderChangeArea,handleSliderChangeCompleteArea,
  getProjectstatusData
}) => {
  React.useEffect(() => {
    if(!isMobile){
      const handleScroll = () => {
          const fixedDiv = document.getElementById('sticky-map-rightsidebar');
          const footer = document.getElementById('footerdiv');

          if (fixedDiv && footer) {
              const footerRect = footer.getBoundingClientRect();
              // const fixedDivRect = fixedDiv.getBoundingClientRect();

              if (window.scrollY > 1 && footerRect.top > window.innerHeight) {
                  fixedDiv.classList.add('stickySidebar');
                  fixedDiv.style.position = 'fixed';
                  fixedDiv.style.bottom = 'initial';
                  fixedDiv.style.top = isMobile ? '114px' :'157px';
              } else if (footerRect.top <= window.innerHeight) {
                  fixedDiv.classList.remove('stickySidebar');
                  fixedDiv.style.position = 'absolute';
                  fixedDiv.style.bottom = '0';
              } else {
                  fixedDiv.classList.remove('stickySidebar');
                  fixedDiv.style.position = 'relative';
                  fixedDiv.style.bottom = 'initial';
                  fixedDiv.style.top = '-10px';
              }
          }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);
  useEffect(() => {
    // Function to prevent default click behavior and manage scrolling
    const handleAccordionClick = (event) => {
      event.preventDefault();
      const target = event.target;
      const accordionItem = target.closest('.accordion-item');
      const body = accordionItem.querySelector('.accordion-body');

      // Toggle the 'show' class on the body
      if (body.classList.contains('show')) {
        body.classList.remove('show');
        body.style.position = '';
        body.style.top = '';
        body.style.left = '';
        body.style.zIndex = '';
      } else {
        body.classList.add('show');
        const rect = accordionItem.getBoundingClientRect();
        body.style.position = 'absolute';
        body.style.top = `${rect.top + window.scrollY}px`;
        body.style.left = `${rect.left}px`;
        body.style.zIndex = '1000';  // Adjust as necessary to ensure it's above other content
      }
    };

    // Attach event listener to all accordion headers
    const accordionHeaders = document.querySelectorAll('.accordion-header');
    accordionHeaders.forEach(header => {
      header.addEventListener('click', handleAccordionClick);
    });

    // Cleanup event listeners on component unmount
    return () => {
      accordionHeaders.forEach(header => {
        header.removeEventListener('click', handleAccordionClick);
      });
    };
  }, []);

  const combinedOnChange = (newRange) => {
    handleSliderChange(newRange);
    // setProjectMaxBudgetFilter(newRange[1]);
  };

  const combinedOnChangeComplete = (newRange) => {

    // console.log(newRange[1])
    handleSliderChangeComplete(newRange);
    setProjectMaxBudgetFilter(newRange[1]);
    createGa4Event('filter_budget', 'BudgetFilter', newRange[0] + "-" + newRange[1])
  }
  const combinedOnChangeArea = (newRangeArea) => {
    handleSliderChangeArea(newRangeArea);
    // setProjectMaxBudgetFilter(newRange[1]);
  };
  const combinedOnChangeCompleteArea = (newRangeArea) => {

    // console.log(newRange[1])
    handleSliderChangeCompleteArea(newRangeArea);
    // setProjectMaxBudgetFilter(newRange[1]);
    createGa4Event('filter_builtup', 'Built Up Filter', newRangeArea[0] + "-" + newRangeArea[1])
  }


  return (
    <>
    <div className='filter-sticky-map1 mt-2' id="filter-sticky1">
      <input type="hidden" name="search" value="" id="plp-search-hidden" />
      <div className='d-flex column-gap-3' >
        <div className=''>
          <Accordion className='top-filter-accordation project-status'>
            <Accordion.Item eventKey="0" className='p-0 accordion-item'>
              <Accordion.Header className={`accordion-header ${selectedStatus.length > 0 && "active"}`}>Project Status
              {selectedStatus.length > 0 &&
                <div className='activeProjectStatus'>
                  <span className={`activeCircle ${selectedStatus.length > 0 && "active"}`}></span>
                  <span className='countNo'> ({selectedStatus.length > 0 && selectedStatus.length})</span>
                </div>
              }
              </Accordion.Header>
              <Accordion.Body className='filter-wrapbody accordion-body'>
                <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("New Project") ? 'active' : ''}`}
                  onClick={() => {
                    toggleStatus("New Project");
                    createGa4Event('Click', 'Project Status Filter', 'New Project');
                  }}>
                  New Project
                </a>
                {/* <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("New Launch") ? 'active' : ''}`}
                  onClick={() => {
                    toggleStatus("New Launch");
                    setProjectStatusFilter("New Launch");
                    createGa4Event('filter_project_status', 'ProjectStatusFilter', 'New Launch');
                  }}>
                  New Launch
                </a> */}
                <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("Under Construction") ? 'active' : ''}`}
                  onClick={() => {
                    toggleStatus("Under Construction");
                    setProjectStatusFilter("Under Construction");
                    createGa4Event('filter_project_status', 'ProjectStatusFilter', 'Under Construction');
                  }}>
                  Under Construction
                </a>
                <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("Ready To Move") ? 'active' : ''}`}
                  onClick={() => {
                    toggleStatus("Ready To Move");
                    setProjectStatusFilter("Ready To Move");
                    createGa4Event('filter_project_status', 'ProjectStatusFilter', 'Ready To Move');
                  }}>
                  Ready To Move
                </a>
                <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("New Launch") ? 'active' : ''}`}
                  onClick={() => {
                    toggleStatus("New Launch");
                    setProjectStatusFilter("New Launch");
                    createGa4Event('filter_project_status', 'ProjectStatusFilter', 'New Launch');
                  }}>
                  New Launch
                </a>
                <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("Nearing Possession") ? 'active' : ''}`}
                  onClick={() => {
                    toggleStatus("Nearing Possession");
                    setProjectStatusFilter("Nearing Possession");
                    createGa4Event('filter_project_status', 'ProjectStatusFilter', 'Nearing Possession');
                  }}>
                  Nearing Possession
                </a>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className=' px-0'>
          <Accordion className='top-filter-accordation configuration-type'>
            <Accordion.Item eventKey="1" className='border-top accordion-item'>
              <Accordion.Header className={`accordion-header ${selectedBHK.length > 0 && "active"}`}>Configuration Type
              {selectedBHK.length > 0 &&
                <div className='activeProjectStatus'>
                  <span className={`activeCircle ${selectedBHK.length > 0 && "active"}`}></span>
                  <span className='countNo'> ({selectedBHK.length > 0 && selectedBHK.length})</span>
                </div>
              }
              </Accordion.Header>
              <Accordion.Body className='filter-wrapbody accordion-body'>
                {filteredByBhk.slice(0, showAllBhk ? filteredByBhk.length : initialDisplayLimit).map((BhkType, index) => (
                  BhkType !== "" && (
                    BhkType && BhkType.bedrooms && BhkType.type &&
                    <a key={index} className={`btn btn-outline-secondary me-2 mb-2 ${selectedBHK.includes(BhkType.bedrooms + " " + BhkType.type.toUpperCase()) ? 'active' : ''}`}
                      onClick={() => {
                        toggleBhkType(BhkType.bedrooms + " " + BhkType.type.toUpperCase());
                        sortProjectsByBhkType(selectedBHK);
                        setSelectedTab(BhkType.bedrooms + " " + BhkType.type.toUpperCase());
                        setProjectConfigurationFilter(BhkType.bedrooms + " " + BhkType.type.toUpperCase());
                        createGa4Event('filter_configuration_type', 'ConfigurationTypeFilter', BhkType.bedrooms + " " + BhkType.type.toUpperCase());
                      }}>
                      {BhkType.bedrooms} {BhkType.type.toUpperCase()}
                    </a>
                  )
                ))}
                {filteredByBhk.length > initialDisplayLimit && (
                  <button className="p-1 pt-0 readmore text-primary text-decoration-underline fw-bold border-0 background-trasparent btn btn-link" onClick={toggleShowAllBhk}>
                    {showAllBhk ? '- Show Less' : '+ Show More'}
                  </button>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className=''>
          <Accordion className='top-filter-accordation possession-status'>
            <Accordion.Item eventKey="4" className='p-0 accordion-item'>
              <Accordion.Header className={`accordion-header ${selectedPossessionStatus.length > 0 && "active"}`}>Possession Status
              {selectedPossessionStatus.length > 0 &&
                <div className='activeProjectStatus'>
                  <span className={`activeCircle ${selectedPossessionStatus.length > 0 && "active"}`}></span>
                  <span className='countNo'> ({selectedPossessionStatus.length > 0 && selectedPossessionStatus.length})</span>
                </div>
              }
              </Accordion.Header>
              <Accordion.Body className='filter-wrapbody accordion-body'>
                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 3 ? 'active' : ''}`}
                                onClick={() => {
                                    togglePossessionStatus('3');
                                    // sortProjectsByStatus(selectedPossessionStatus);
                                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 3 months')
                                }}>
                                In 3 months
                            </a>
                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 6 ? 'active' : ''}`}
                                onClick={() => {
                                    togglePossessionStatus('6');
                                    // setProjectStatusFilter("In 6 months");
                                    // sortProjectsByStatus(selectedPossessionStatus);
                                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 6 months')
                                }}>
                                In 6 months
                            </a>
                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 12 ? 'active' : ''}`}
                                onClick={() => {
                                    togglePossessionStatus('12');
                                    // setProjectStatusFilter("In 1 year");
                                    // sortProjectsByStatus(selectedPossessionStatus);
                                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 1 year')
                                }}>
                                In 1 year
                            </a>
                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 24 ? 'active' : ''}`}
                                onClick={() => {
                                    togglePossessionStatus('24');
                                    // setProjectStatusFilter("In 2 years");
                                    // sortProjectsByStatus(selectedPossessionStatus);
                                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 2 years')
                                }}>
                                In 2 years
                            </a>
                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 36 ? 'active' : ''}`}
                                onClick={() => {
                                    togglePossessionStatus('36');
                                    // setProjectStatusFilter("In 3 years");
                                    // sortProjectsByStatus(selectedPossessionStatus);
                                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 3 years')
                                }}>
                                In 3 years
                            </a>
                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 60 ? 'active' : ''}`}
                                onClick={() => {
                                    togglePossessionStatus('60');
                                    // setProjectStatusFilter("In 5 years");
                                    // sortProjectsByStatus(selectedPossessionStatus);
                                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 5 years')
                                }}>
                                In 5 years
                            </a>
                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 84 ? 'active' : ''}`}
                                onClick={() => {
                                    togglePossessionStatus('84');
                                    // setProjectStatusFilter("In 7 years");
                                    // sortProjectsByStatus(selectedPossessionStatus);
                                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 7 years')
                                }}>
                                In 7 years
                            </a>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className=''>
          <Accordion className='top-filter-accordation budget'>
            <Accordion.Item eventKey="2" className='border-top accordion-item'>
              <Accordion.Header className={`accordion-header ${priceRange[0] !== 500000 &&  "active"} ${priceRange[1] !== 50000000 &&  "active"}`}>Budget</Accordion.Header>
              <Accordion.Body>
                <Slider
                  range
                  min={500000}
                  max={50000000}
                  value={priceRange}
                  onChange={combinedOnChange}
                  onChangeComplete={combinedOnChangeComplete}
                />
                <p className='d-flex justify-content-between'>
                  <span>{`₹${formatNumber(priceRange[0])}`}</span>
                  <span>{`₹${formatNumber(priceRange[1])}`}{priceRange[1] === 50000000 && '+'}</span>
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className=' px-0'>
          <Accordion className='top-filter-accordation built-up-area'>
            <Accordion.Item eventKey="3" className='border-top accordion-item '>
              <Accordion.Header className={`accordion-header ${builtUpArea[0] !== 100 &&  "active"} ${builtUpArea[1] !== 5000 &&  "active"}`}>Built Up Area (Sq.Ft.)</Accordion.Header>
              <Accordion.Body>
                <Slider
                  range
                  min={100}
                  max={5000}
                  value={builtUpArea}
                  onChange={combinedOnChangeArea}
                  onChangeComplete={combinedOnChangeCompleteArea}
                />
                <p className='d-flex justify-content-between'>
                  <span>{`${builtUpArea[0]} Sq.Ft.`}</span>
                  <span>{`${builtUpArea[1]} Sq.Ft.`}{builtUpArea[1] === 5000 && '+'}</span>
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      </div>
    </>
  );
};

export default NewFilterSidebar;
