import React, { useEffect, useRef, useContext } from 'react';
import { appAbsPath, executeLaravelAPI } from './../../Admin/Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchVisibilityContext } from './SearchVisibilityContext';
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';
import { setProjectSearchLocation, setProjectSearchDeveloper, setProjectSearchConfiguration, setProjectMaxBudgetFilter, setProjectSearchProjectStatus, setProjectSearchPossessionMonths } from '../../Admin/UserActivatyTracker';
import { createGa4Event } from '../../Admin/Utils';
import searchDataJson from '../../../Assets/search-json/pune_master_search.json';

const SearchInput = ({ onSearchResults, closeSelectedCallback, handleClose, islocality=false, irfs=false}) => {
  const selectHeaderRef = useRef();
  const hiddenInputRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const { isVisible, searchOptions } = useContext(SearchVisibilityContext);

  // Hide select2 dropdown if isVisible changes
  useEffect(() => {
    if (selectHeaderRef.current && isVisible === false && islocality === false) {
      const $element = $(selectHeaderRef.current);
      if ($element.data('select2')) {
        $element.select2('close');
      }
    }
  }, [isVisible]);

  useEffect(() => {
    if (searchOptions && islocality === false) {
      const $selectElement = $(selectHeaderRef.current);
      const optionToUnselect = $selectElement.find('option').filter(function () {
        return $(this).text() === searchOptions;
      });

      if (optionToUnselect.length > 0) {
        optionToUnselect.prop('selected', false);
        // console.log('tyehububujbbbbbb');
        storeSelectedValues();
        storeSelectedValuesInHiddenInput();
        updateUrlParams();
        $selectElement.trigger('change'); // Trigger the change event to fire select2:unselect
      }
    }
  }, [searchOptions]);

  let defaultLocations = searchDataJson.locations.map((item, index) => ({
    id: `default-${index + 1}`,
    text: item.location,
    type: 'locality',
    irfs: item.is_irfs
  }));

  let defaultLocationsBySlug = searchDataJson.locality_by_slug.map((item, index) => ({
    id: `location-${index + 1}`,
    text: item.name,
    type: 'locality_by_slug',
    slug: item.slug
  }));

  let defaultDevelopers = searchDataJson.developers.map((item, index) => ({
    id: item.id,
    text: item.name,
    type: 'developer',
    irfs: item.is_irfs
  }));

  let defaultProjects = searchDataJson.projects.map((item, index) => ({
    id: item.id,
    text: item.name,
    type: 'project',
    slug: item.slug,
    irfs: item.is_irfs
  }));

  let defaultConfigurations = searchDataJson.configurations.map((item, index) => ({
    id: `configurations-${index + 1}`,
    text: `${item.bedrooms} BHK`,
    type: 'configuration',
    slug: item.slug || '',
    bhk: item.bedrooms
  }));

  const updateUrlParams = async () => {
    const selectedValues = $(selectHeaderRef.current).select2('data');

    // Check if locality is selected and redirect to /location/data
    if (islocality && selectedValues.length > 0) {
      // Redirect to /location/data when locality is selected
      const selectedLocation = selectedValues.find(value => value.type === 'locality_by_slug');
      if (selectedLocation) {
        createGa4Event('search_locality', 'LocalityTypeSearch', selectedLocation.text);
        navigate(`/location/${(selectedLocation.slug).toLowerCase()}`);
        return; // Exit the function to prevent further URL updates
      }
    }

    const urlParams = new URLSearchParams(location.search);

    let localities = [];
    let configurations = [];
    let projectSlug = '';
    let locality_by_slug = [];

    if (selectedValues.length === 0) {
      for (let param of urlParams.keys()) {
        urlParams.delete(param);
      }
      localStorage.removeItem('selectedValues');
      hiddenInputRef.current.value = '';
      const currentUrl = window.location.origin + window.location.pathname;
      if (currentUrl.includes('/map-view')) {
        if(currentUrl.includes('irfs')){
          navigate(`/irfs/projects/map-view`);
        }else{
          navigate(`/projects/map-view`);
        }
      }else if(currentUrl.includes('irfs')){
        navigate(`/irfs/projects`);
      }
      else{
        navigate(`/projects`);
      }
      return;
    }

    for (const selectedData of selectedValues) {
      switch (selectedData.type) {
        case 'configuration':
          configurations.push(selectedData.bhk);
          setProjectSearchConfiguration(selectedData.bhk);
          createGa4Event('search_configuration_type', 'ConfigurationTypeSearch', selectedData.bhk);
          break;
        case 'project':
          let projectid = selectedData.id;
          // console.log('ProjectSearch', selectedData.text)
          createGa4Event('search_project', 'ProjectSearch', selectedData.text);
          projectSlug = selectedData.slug;
          break;
        case 'developer':
          urlParams.set('searchtype', 'developers');
          urlParams.set('developer', selectedData.id);
          setProjectSearchDeveloper(selectedData.id);
          // console.log('bhk:', selectedData.text);
          createGa4Event('search_developer', 'DeveloperSearch', selectedData.text);
          break;
        case 'locality':
          localities.push(selectedData.text);
          setProjectSearchLocation(selectedData.text);
          createGa4Event('search_locality', 'LocalitySearch', selectedData.text);
          break;
        case 'locality_by_slug':
          locality_by_slug.push(selectedData.text);
          setProjectSearchLocation(selectedData.text);
          createGa4Event('search_locality', 'LocalitySearch', selectedData.text);
        case 'nlptext':
          let searchNlpPromise = await executeLaravelAPI('searchnlp', { query: selectedData.query }, 'GET');
          searchNlpPromise = JSON.parse(searchNlpPromise);
          let property_configuration = searchNlpPromise?.property_configuration;
          let property_location = searchNlpPromise?.property_location;
          let property_budget = searchNlpPromise?.property_budget;
          let project_status = searchNlpPromise?.project_status;
          let possession_months = searchNlpPromise?.possession_months;
          storeSelectedValues(property_location);

          for (let param of urlParams.keys()) {
            urlParams.delete(param);
          }

          if (property_configuration) {
            // console.log('property_configuration:', property_configuration);

            let bhk = '';
            // Updated regex to match numbers with optional decimals
            let matches = property_configuration.match(/(\d+(\.\d+)?)\s*([a-zA-Z]*)/);

            if (matches && matches.length >= 3) {
              let number = matches[1]; // This will now capture 2.5 correctly
              let type = matches[3].toUpperCase(); // Optional type (e.g., BHK)
              bhk = number;
              property_configuration = type ? `${number}+${type}` : number;
            }

            // console.log('bhk:', bhk);
            configurations.push(bhk);
            setProjectSearchConfiguration(property_configuration);
            createGa4Event('search_configuration_type_nlp', 'ConfigurationTypeSearchNlp', property_configuration);
          }
          if (property_location) {
            localities.push(property_location);
            setProjectSearchLocation(property_location);
            createGa4Event('search_locality_nlp', 'LocalitySearchNlp', property_location);
          }
          if (property_budget) {
            urlParams.set('maxprice', property_budget);
            setProjectMaxBudgetFilter(property_budget);
            createGa4Event('search_budget_nlp', 'BudgetSearchNlp', property_budget);
          }

          if (project_status) {
            urlParams.set('project_status', project_status);
            setProjectSearchProjectStatus(project_status);
            createGa4Event('search_project_status_nlp', 'ProjectStatusSearchNlp', project_status);
          }

          if (possession_months) {
            urlParams.set('possession_months', possession_months);
            setProjectSearchPossessionMonths(possession_months);
            createGa4Event('search_possession_nlp', 'PossessionSearchNlp', possession_months);
          }

          urlParams.set('nlp', '1');
          break;

        default:
          break;
      }
    }

    if (localities.length > 0) {
      urlParams.set('searchtype', 'locality_name');
      urlParams.set('locality_name', localities.join(','));
    } else {
      urlParams.delete('locality_name');
    }

    if (configurations.length > 0) {
      urlParams.set('bhk', configurations.join(','));
      let configurations1 = configurations.map(configuration => configuration + "+BHK");
      urlParams.set('configuration', configurations1.join(','));
      urlParams.set('type', 'bhk');
    } else {
      urlParams.delete('configuration');
    }
//  // Adding the extra parameter
//  urlParams.set('extra_param', 'my_extra_value');
    
    if (projectSlug) {
      const currentUrl = window.location.origin + window.location.pathname;
      if(currentUrl.includes('irfs')){
        navigate(`${appAbsPath}/irfs/project/${projectSlug}`);
      }else{
        navigate(`${appAbsPath}/project/${projectSlug}`);
      }
    } else {
      const currentUrl = window.location.origin + window.location.pathname;
      let paramString = urlParams.toString().replace(/%2B/g, '+');
      // # if url constains map-view them navigate to /map-view?${paramString} 
      if (currentUrl.includes('/map-view')) {
        if(currentUrl.includes('irfs')){
          navigate(`/irfs/projects/map-view?${paramString}`);
        }else{
          navigate(`/projects/map-view?${paramString}`);
        }
      } 
      else if(currentUrl.includes('irfs')){
        navigate(`/irfs/projects?${paramString}`);
      }
      else {
        navigate(`/projects?${paramString}`);
      }
    }

    if (onSearchResults) {
      onSearchResults(selectedValues);
    }
  };

  const storeSelectedValues = (additionalData = {}) => {
    const selectedValues = $(selectHeaderRef.current).select2('data').map(item => {
      const matchedLocation = defaultLocations.find(loc => loc.id === item.id);
      const matchedDeveloper = defaultDevelopers.find(dev => dev.text === item.text);
      const matchedProject = defaultProjects.find(proj => proj.text === item.text);
      const matchedConfiguration = defaultConfigurations.find(conf => conf.id === item.id);

      if (matchedLocation) {
        item.type = 'locality';
      } else if (matchedDeveloper) {
        item.type = 'developer';
      } else if (matchedProject) {
        item.type = 'project';
      } else if (matchedConfiguration) {
        item.type = 'configuration';
        item.bhk = matchedConfiguration.bhk;
      } else if (additionalData.type) {
        item.type = additionalData.type;
        item.query = additionalData.query;
      }

      return {
        id: item.id,
        text: item.text,
        type: item.type,
        slug: item.slug || '',
        bhk: item.bhk || ''
      };
    });

    localStorage.setItem('selectedValues', JSON.stringify(selectedValues));
  };

  const storeSelectedValuesInHiddenInput = () => {
    if(islocality === false){
    const selectedValues = $(selectHeaderRef.current).select2('data').map(item => ({
      id: item.id,
      text: item.text,
      type: item.type,
      slug: item.slug || '',
      bhk: item.bhk || ''
    }));
    hiddenInputRef.current.value = JSON.stringify(selectedValues);}
  };

  const restoreSelectedValues = () => {
    if (location.pathname === '/' || location.pathname.includes('/project') || islocality || location.pathname === '/irfs') return;
    const selectedValues = JSON.parse(localStorage.getItem('selectedValues'));
    if (selectedValues && selectedValues.length > 0) {
      $(selectHeaderRef.current).empty();
      selectedValues.forEach(item => {
        if ($(selectHeaderRef.current).find(`option[value='${item.id}']`).length === 0) {
          const option = new Option(item.text, item.id, item.type, true, true);
          $(selectHeaderRef.current).append(option).trigger('change');
        }
      });
    }
    storeSelectedValues(); // Store default selected values
  };

  const initializeFromUrl = async () => {
    const urlParams = new URLSearchParams(location.search);
    const searchType = urlParams.get('searchtype');
    const localityName = urlParams.get('locality_name');
    const bhk = urlParams.get('bhk');
    const nlp = urlParams.get('nlp');

    if (searchType === 'locality_name' && localityName) {
      const localities = localityName.split(',').map(locality => locality.trim().toLowerCase());

      localities.forEach(locality => {
        const localityObj = defaultLocations.find(loc => loc.text.toLowerCase() === locality.toLowerCase());
        if (localityObj && $(selectHeaderRef.current).find(`option[value='${localityObj.id}']`).length === 0) {
          const option = new Option(localityObj.text, localityObj.id, localityObj.type, true, true);
          $(selectHeaderRef.current).append(option).trigger('change');
        }
      });

      storeSelectedValues();
    }

    if (nlp === '1' && urlParams.get('query')) {
      const query = urlParams.get('query');
      let searchNlpPromise = await executeLaravelAPI('searchnlp', { query }, 'GET');
      searchNlpPromise = JSON.parse(searchNlpPromise);

      const property_location = searchNlpPromise?.property_location;
      const property_configuration = searchNlpPromise?.property_configuration;
      const property_budget = searchNlpPromise?.property_budget;

      const selectedValues = [];

      if (property_location) {
        const localityObj = defaultLocations.find(loc => loc.text.toLowerCase() === property_location.toLowerCase());
        if (localityObj) {
          selectedValues.push({
            id: localityObj.id,
            text: localityObj.text,
            type: 'locality'
          });
          setProjectSearchLocation(localityObj.text);
          createGa4Event('search_locality_nlp', 'LocalitySearchNlp', property_location);
        }
      }

      if (property_configuration) {
        const bhkObj = defaultConfigurations.find(conf => conf.text === property_configuration);
        if (bhkObj) {
          selectedValues.push({
            id: bhkObj.id,
            text: bhkObj.text,
            type: 'configuration',
            bhk: bhkObj.bhk
          });
          setProjectSearchConfiguration(bhkObj.text);
          createGa4Event('search_configuration_type_nlp', 'ConfigurationTypeSearchNlp', property_configuration);

        }
      }

      if (property_budget) {
        urlParams.set('maxprice', property_budget);
        setProjectMaxBudgetFilter(property_budget);
        createGa4Event('search_budget_nlp', 'BudgetSearchNlp', property_budget);
      }

      storeSelectedValues();
    }
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      const input = $(selectHeaderRef.current).data('select2').$dropdown.find('.select2-search__field');
      if (input.val().trim() !== '') {
        const results = $(selectHeaderRef.current).data('select2').$results.find('.select2-results__option');
        if (!results.length) {
          storeSelectedValuesInHiddenInput();
          storeSelectedValues({ type: 'nlptext', query: input.val().trim() });
          navigate(`${location.pathname}?searchtype=nlp&query=${input.val().trim()}`);
        }
      }
    }
  };

  useEffect(() => {
    window.$ = $;
    // console.log('islocality ', islocality);
    $(selectHeaderRef.current).select2({
      placeholder: islocality ? 'Search for locality' : 'Search for locality, developer, project, or configuration',
      minimumInputLength: 0,
      multiple: true,
      ajax: {
        transport: function (params, success, failure) {
          const term = params.data.term ? params.data.term.toLowerCase() : '';
          let results = [];

          if (term.length < 3) {
            if (islocality) {
              success({
                results: [
                  { text: 'Locations', children: defaultLocationsBySlug },
                ]
              });
            } else {
              if (irfs || location.pathname.includes('irfs')) {
              defaultLocations = defaultLocations.filter(loc => loc.irfs);
              defaultProjects = defaultProjects.filter(proj => proj.irfs);
              defaultDevelopers = defaultDevelopers.filter(proj => proj.irfs);
              defaultConfigurations = defaultConfigurations;
                success({
                  results: [
                    { text: 'Locations', children: defaultLocations.filter(loc => loc.irfs) },                    
                    { text: 'Projects', children: defaultProjects.filter(proj => proj.irfs) },
                    { text: 'Developers', children: defaultDevelopers.filter(proj => proj.irfs) },
                    { text: 'Configuration', children: defaultConfigurations }
                  ]
                });
              } else {
                success({
                  results: [
                    { text: 'Locations', children: defaultLocations },                    
                    { text: 'Projects', children: defaultProjects },
                    { text: 'Developers', children: defaultDevelopers },
                    { text: 'Configuration', children: defaultConfigurations }
                  ]
                });
              }
            }
            return;
          }
          let filteredLocationsBySlug =  islocality ? defaultLocationsBySlug.filter(loc => loc.text.toLowerCase().includes(term)):[];
          let filteredLocations =  islocality ? [] : defaultLocations.filter(loc => loc.text.toLowerCase().includes(term));
          let filteredDevelopers =  islocality ? [] : defaultDevelopers.filter(dev => dev.text.toLowerCase().includes(term));
          let filteredProjects =  islocality ? [] : defaultProjects.filter(proj => proj.text.toLowerCase().includes(term));
          let filteredConfigurations =  islocality ? [] : defaultConfigurations.filter(conf =>
            conf.text.replace(/\s+/g, '').toLowerCase().includes(term.replace(/\s+/g, '').toLowerCase())
          );

          if (islocality) {
            if (filteredLocationsBySlug.length > 0) {
              results.push({ text: 'Locations', children: filteredLocationsBySlug });
            }
          }
          if (!islocality) {
            if (filteredLocations.length > 0) {
              results.push({ text: 'Locations', children: filteredLocations });
            }
            if (filteredProjects.length > 0) {
              results.push({ text: 'Projects', children: filteredProjects });
            }
            if (filteredDevelopers.length > 0) {
              results.push({ text: 'Developers', children: filteredDevelopers });
            }           
            if (filteredConfigurations.length > 0) {
              results.push({ text: 'Configuration', children: filteredConfigurations });
            }            
          }

          if (results.length === 0 && islocality === false) {
            results.push({
              text: 'Search Powered by GPT',
              children: [{ id: `nlp-${term}`, text: term, type: 'nlptext', query: term }]
            });
          }
          success({ results });
        },
        delay: 100
      }
    })
      .on('select2:open', function () {
        $(selectHeaderRef.current).data('select2').$dropdown.find('.select2-search__field').on('keydown', handleEnterKey);
      })
      .on('select2:close', function () {
        $(selectHeaderRef.current).data('select2').$dropdown.find('.select2-search__field').off('keydown', handleEnterKey);
      })
      .on('select2:select', function () {
        storeSelectedValues();
        storeSelectedValuesInHiddenInput();
        updateUrlParams();
        // alert('select');

        // Call handleClose to close the popup
        if (handleClose) {
          handleClose();
        }
      })
      .on('select2:unselect', function () {
        //console.log('unselect');
        storeSelectedValues();
        storeSelectedValuesInHiddenInput();
        updateUrlParams();
      });

    restoreSelectedValues();
    initializeFromUrl();

    return () => {
      $(selectHeaderRef.current).off('select2:open');
      $(selectHeaderRef.current).off('select2:close');
      $(selectHeaderRef.current).off('select2:select');
      $(selectHeaderRef.current).off('select2:unselect');
    };
  }, [navigate, location, islocality]);

  useEffect(() => {
    return () => {
      if (location.pathname === '/' || location.pathname.includes('/project/') || location.pathname === '/irfs') {
        $(selectHeaderRef.current).empty();
        localStorage.removeItem('selectedValues');
      }
    };
  }, [location.pathname]);

  const handleSearchButtonClick = () => {
    storeSelectedValuesInHiddenInput();
    updateUrlParams();
  };

  return (
    <>
    {!islocality &&(
      <select className='cityDropdown'>
        <option>Pune</option>
      </select>
    )}
      <select ref={selectHeaderRef} className="form-control search-input"></select>
      <input type="hidden" ref={hiddenInputRef} />
      <a className='searchBtn d-none d-lg-block' onClick={handleSearchButtonClick}>Search</a>
    </>
  );
};

export default SearchInput;
