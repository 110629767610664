import React, { useState, useEffect, lazy } from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { executeLaravelFrontAPI } from './../../Admin/Utils'; // Ensure this is the correct path or adapt as necessary
import { Icon } from '@iconify/react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProjectCard from '../Layouts/ProjectCard';
import ProjectCardListing from './ProjectCardListing';
import InfiniteScroll from 'react-infinite-scroll-component';

const RelatedProjects = ({
    project,
    locationName,
    slidesToShow = [4, 3, 2, 1],
    isWishlistActive,
    is_irfs = false,
    isProjectListing = false,
    selectedTab,
    handleTabClick,
    getMinMaxCarpet,
    appAbsPath,
    userdetails,
    loggeduserdetails,
    locality_name,
    totalListProjCount = 0
}) => {
    const [relatedProjects, setRelatedProjects] = useState([]);
    const navigate = useNavigate();
    const [hasMore, setHasMore] = useState(true); // To control the Infinite Scroll
    const [page, setPage] = useState(1); // Current page for fetching data
    const scrollContainerRef = React.createRef();
    const [isloading, setIsLoading] = useState(true);

    useEffect(() => {
        if (locationName !== null && locationName !== undefined && locationName !== '' && !Array.isArray(locationName)) {
            fetchRelatedProjects(locationName); // Initial fetch
            setIsLoading(false);
        }
    }, [page, locationName]);
    const fetchRelatedProjects = async (locationName) => {
        try {

            const paramsData = {
                locality_name: locationName,
                is_irfs: is_irfs,
                searchtype: 'locality_name',
                per_page: 5,
                page: page, // Fetch data by page
            };

            const result = await executeLaravelFrontAPI('projects', paramsData, 'GET');
            setRelatedProjects(prevProjects => {
                // Create a Set of existing project IDs for quick lookup
                const existingIds = new Set(prevProjects.map(p => p.id));

                // Filter out new projects that already exist in the state
                const newUniqueProjects = result.data.data.filter(project => !existingIds.has(project.id));

                // Return the new array combining old projects and filtered new projects
                return [...prevProjects, ...newUniqueProjects];
            });


            setHasMore(result.data.data.length > 0); // Update if more data is available

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const loadMoreProjects = () => {
        if (isScrolledNearBottom(scrollContainerRef.current)) {
            setPage(prevPage => prevPage + 1);
        }
    };

    const isScrolledNearBottom = (element) => {
        if (!element) return false;
        const threshold = 100; // Pixels from the bottom of the element to trigger loading more items
        const position = element.scrollHeight - element.scrollTop - element.clientHeight;
        return position < threshold;
    };

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', loadMoreProjects);
        }
        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', loadMoreProjects);
            }
        };
    }, [scrollContainerRef.current]); // Ensure the listener is updated if the ref changes


    // useEffect(() => {
    //     const fetchRelatedProjects = async () => {
    //         try {
    //             const paramsData = {
    //                 locality_name: locationName ?? '',
    //                 is_irfs: is_irfs,
    //                 searchtype: 'locality_name',
    //                 per_page: 15,
    //             };
    //             const result = await executeLaravelFrontAPI('projects', paramsData, 'GET');
    //             setRelatedProjects(result.data.data);
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     };

    //     fetchRelatedProjects();
    // }, [locationName]);

    // console.log('isProjectListing ', isProjectListing);

    const handlerelatedProj = (projectSlug) => {
        window.scrollTo(0, 0);
        navigate(`/project/${projectSlug}`);
    };

    const [slidesToShowDesk, slidesToShowLarge, slidesToShowIpro, slidesToShowIpad, slidesToShowMob] = slidesToShow;
    const settingsForRelatedProject = {
        dots: false,
        arrows: true,
        infinite: relatedProjects.length > slidesToShowDesk ? true : false,
        autoplay: true,
        speed: 800,
        slidesToShow: relatedProjects.length > slidesToShowDesk ? slidesToShowDesk : relatedProjects.length,
        slidesToScroll: 1,
        centerMode: false,
        variableWidth: true,
        prevArrow: <Icon icon="ri:arrow-left-s-line" color="#000" className="prev-arrow" />,
        nextArrow: <Icon icon="ri:arrow-right-s-line" color="#000" className="next-arrow" />,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: slidesToShowLarge || 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: slidesToShowIpro || 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 993,
                settings: {
                    slidesToShow: slidesToShowIpad || 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 641,
                settings: {
                    slidesToShow: slidesToShowMob || 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                },
            },
        ],
    };

    return (

        isloading ? <div className="custom-loader text-center m-auto"></div> :
            <>
                {
                    totalListProjCount != 0 && (
                        <div className="noProjectWrapper mt-4 mb-4">
                            <p className="text-center" style={{ textWrap: 'balance' }}>
                                <Icon
                                    icon="fa6-regular:face-sad-tear"
                                    className="me-1 mb-1 align-middle"
                                    color="#667486"
                                />
                                You've reached the end of the list.
                                <br />
                                Explore nearby projects or refine your search for more options.
                            </p>
                        </div>
                    )
                }
                <section ref={scrollContainerRef} className={`similer-project ${isProjectListing == 'true' ? 'p-0' : ''} `}>
                    <h2 className="fw-bold mb-3 mt-2">Explore Near By Projects</h2>
                    {isProjectListing == 'true' && relatedProjects.length > 0 ?
                        <InfiniteScroll
                            dataLength={relatedProjects.length}
                            next={loadMoreProjects}
                            hasMore={hasMore}
                            loader={<h4><span className="custom-loader m-auto"></span></h4>}
                        // endMessage={}
                        >
                            {relatedProjects
                                .filter((relatedProject) => {
                                    // Only filter by project name if the project prop is provided
                                    if (project) {
                                        return relatedProject.name !== project.name;
                                    }
                                    return true;  // If no project is passed, include all related projects
                                })
                                .map((filteredProject, index) => (
                                    <ProjectCardListing
                                        key={index}
                                        project={filteredProject}
                                        selectedTab={selectedTab}
                                        handleTabClick={handleTabClick}
                                        getMinMaxCarpet={getMinMaxCarpet}
                                        appAbsPath={appAbsPath}
                                        userdetails={userdetails}
                                        isWishlistActive={isWishlistActive}
                                        loggeduserdetails={loggeduserdetails}
                                        locality_name={locationName}
                                    />

                                ))}
                        </InfiniteScroll>
                        :
                        isProjectListing != 'true' && (
                            <Slider {...settingsForRelatedProject} className="top-projects-slider">
                                {relatedProjects
                                    .filter((relatedProject) => {
                                        // Only filter by project name if the project prop is provided
                                        if (project) {
                                            return relatedProject.name !== project.name;
                                        }
                                        return true;  // If no project is passed, include all related projects
                                    })
                                    .map((filteredProject, index) => (
                                        <ProjectCard key={filteredProject.id} project={filteredProject} isWishlistActive={isWishlistActive} showWishlist="yes" />
                                    ))}
                            </Slider>

                        )

                    }

                </section>
            </>

    );
};

export default RelatedProjects;
